import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { IMinistry } from './Model';

const MinistrySchema = Yup.object().shape({
    ministry_category_id: Yup.number().required('Ministry Category is required'),
    menu: Yup.string().required('Menu is required'),
    title: Yup.string().required('Title is required')
});

export const useMinistryUpdate = (lang?: string) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ministry, setMinistry] = useState<IMinistry>({
        ministry_category_id: undefined,
        menu: '',
        link: '',
        title: '',
        subscribe_type_id: undefined,
        join_link: '',
        join_link_enable: 0,
        lang: lang
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            axios
                .get(`${API.MINISTRY}${id}${lang ? `?lang=${lang}` : ''}`)
                .then(res => setMinistry({
                    ...res.data,
                    ...(lang ? { lang: lang } : {})
                }))
                .catch(err => toast.error('Error fetching ministry!'))
                .finally(() => setLoading(false));
        }
    }, [id])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: ministry,
        validationSchema: MinistrySchema,
        onSubmit: (values, { setSubmitting }) => {
            let formData = new FormData();
            if (id) {
                formData.append('_method', 'PUT');
            }
            Object.keys(values).forEach(key => {
                const d = values as any;
                if (d[key] instanceof Array) {
                    d[key].forEach((item: any) => {
                        formData.append(key + '[]', item);
                    });
                    return;
                }
                formData.append(key, d[key] ?? "");
            });

            setLoading(true);
            if (id) {
                axios
                    .post(`${API.MINISTRY}${id}`, formData)
                    .then(res => {
                        toast.success('Ministry updated successfully!');
                    })
                    .catch(err => toast.error('Error updating ministry!'))
                    .finally(() => setLoading(false));
            } else {
                axios
                    .post(API.MINISTRY, formData)
                    .then(res => {
                        toast.success('Ministry created successfully!');
                        navigate('/ministries/form/' + res.data.id, { replace: true });
                    })
                    .catch(err => toast.error('Error creating ministry!'))
                    .finally(() => setLoading(false));
            }

            setSubmitting(false);
        }
    });

    const handleSubmit = formik.handleSubmit;
    return { id, loading, formik, handleSubmit };
}