import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { DisplayType, IMedia, LinkType, MediaCategory } from './Model';

const MediaSchema = Yup.object().shape({
    category: Yup.string().required('Required'),
    section: Yup.string().required('Required'),
    link_type: Yup.string().required('Required'),
    display_type: Yup.string().required('Required'),
    max_items: Yup.number().required('Required')

});

export const useMediaUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [media, setMedia] = useState<IMedia>({
    category: MediaCategory.Sermons,
    section: '',
    link_type: LinkType.Youtube,
    display_type: DisplayType.Recent,
    max_items: 0,
    items: []
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.MEDIA}${id}`)
        .then(res => setMedia({
          ...res.data
        }))
        .catch(err => toast.error('Error fetching media!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: media,
    validationSchema: MediaSchema,
    onSubmit: (values, { setSubmitting }) => {
        const apiUrl = id ? `${API.MEDIA}${id}` : API.MEDIA;
        const successMessage = id ? 'Media updated successfully!' : 'Media created successfully!';
        const errorMessage = id ? 'Error updating media!' : 'Error creating media!';

        values.items = values.items.slice(0, values.max_items);

        setLoading(true);
  
        axios
        .request({
          method: id ? 'put' : 'post',
          url: apiUrl,
          data: values
        })
        .then(() => {
          toast.success(successMessage);
          navigate('/medias');
        })
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error(errorMessage);
          setLoading(false);
        });
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { id, loading, formik, handleSubmit };
};
