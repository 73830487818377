export const baseImagePath = "https://api.gbigilgal.org/storage/";
const baseAPIPath = "https://api.gbigilgal.org/api/";

export const API = {
    SLIDE: baseAPIPath + "slides/",
    SERVICE: baseAPIPath + "services/",
    LEADER: baseAPIPath + "leaders/",
    LANGUAGE: baseAPIPath + "languages/",
    LEADER_TYPE: baseAPIPath + "leader-types/",
    VISIT_US: baseAPIPath + "visit-us/",
    MINISTRY_CATEGORY: baseAPIPath + "ministry-categories/",
    MINISTRY: baseAPIPath + "ministries/",
    EVENT: baseAPIPath + "events/",
    MEDIA: baseAPIPath + "medias/",
    GIVE: baseAPIPath + "gives/",
    ACCESS_CONTROL: baseAPIPath + "access-controls/",
    USER: baseAPIPath + "users/",
    SUBSCRIBE_TYPE: baseAPIPath + "subscribe-types/",
    EMAIL_SETTING: baseAPIPath + "email-settings/",
    LINKS: baseAPIPath + "links/",
    LOGIN: baseAPIPath + "login",
    LOGOUT: baseAPIPath + "logout",
    CHANGE_PASSWORD: baseAPIPath + "change-password",
}