import { AccessControlForm } from "./AccessControlForm";

const AccessControlUpdate = () => {
  return (
    <>
      <AccessControlForm />
    </>
  );
}

export default AccessControlUpdate;
