import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { API } from "../../API";
import { IAccessControl } from "../AccessControl/Model";
import { UserForm } from "./UserForm";

const UserUpdate = () => {
  const [accessControls, setAccessControls] = useState<IAccessControl[]>([]);
  const [loadingAC, setLoadingAC] = useState<boolean>(false);

  useEffect(() => {
    setLoadingAC(true);
    axios
      .get(API.ACCESS_CONTROL)
      .then(res => setAccessControls(res.data))
      .finally(() => setLoadingAC(false))
      .catch(err => toast.error('Error fetching access controls!'));
  }, []);

  if (loadingAC) return (<>
    <Spinner color="light" /> Loading Access Control
  </>
  );

  return (
    <>
      <UserForm accessControls={accessControls} />
    </>
  );
}

export default UserUpdate;
