import { FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import { IAccessControl } from "../AccessControl/Model";
import { ITranslationProps } from "../Language/ITranslationProps";
import useUserUpdate from "./useUserUpdate";

interface UserFormProps extends ITranslationProps {
    accessControls: IAccessControl[]
}

export const UserForm = (props: UserFormProps) => {
    const { lang, langName, accessControls } = props;
    const { id, loading, formik, handleSubmit } = useUserUpdate(lang);

    return (
        <>
            <GenericForm title={lang ? langName : id ? 'Edit User' : 'Add User'} onSubmit={handleSubmit} loading={loading}>
                <>
                    <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            disabled={loading}
                        />
                        {formik.errors.name && <div className="text-danger">{formik.errors.name}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            disabled={loading}
                        />
                        {formik.errors.email && <div className="text-danger">{formik.errors.email}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label>Access Control</Label>
                        <Input
                            type="select"
                            name="access_control_id"
                            onChange={formik.handleChange}
                            value={formik.values.access_control_id}
                            invalid={formik.touched.access_control_id && !!formik.errors.access_control_id}
                            disabled={loading}
                        >
                            <option>Select Access Control</option>
                            {accessControls.map((accessControl) => (
                                <option key={accessControl.id} value={accessControl.id}>{accessControl.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            disabled={loading}
                        />
                        {formik.errors.password && <div className="text-danger">{formik.errors.password}</div>}
                    </FormGroup>
                </>
            </GenericForm>
        </>
    );
};
