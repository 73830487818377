import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from "reactstrap";
import { API } from "../../API";
import ConfirmationModal from "../../Component/ConfirmationModal";
import { useModal } from "../../Hook/useModal";
import { IMinistryCategory } from "./Model";

export const MinistryCategory = () => {
    const [ministryCategories, setMinistryCategories] = useState<IMinistryCategory[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { modalOpen, data, openModal, closeModal } = useModal();

    useEffect(() => {
        axios
            .get(API.MINISTRY_CATEGORY)
            .then(res => setMinistryCategories(res.data))
            .catch(err => toast.error('Error fetching ministry categories!'));
    }, []);

    const confirmDeleteItem = () => {
        if (data !== null && data.id !== undefined) {
            setLoading(true);
            axios
                .delete(`${API.MINISTRY_CATEGORY}${data.id}`)
                .then(() => {
                    toast.success('Ministry Category deleted successfully!');
                    setMinistryCategories(ministryCategories.filter(mc => mc.id !== data.id));
                })
                .finally(() => setLoading(false))
                .catch(err => {
                    toast.error('Error deleting Ministry Category!');
                    setLoading(false);
                });
        }
        closeModal();
    };

    const cancelDeleteItem = () => {
        closeModal();
    };

    const goToForm = (id?: number) => {
        navigate(`/ministry-categories/form${id ? `/${id}` : ''}`);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Ministry Categories</h1>
                </Col>
                <Col className="align-right">
                    <Button color="primary" onClick={() => goToForm()}>
                        Add New
                    </Button>
                </Col>
            </Row>

            <Card className="mt-4">
                <CardHeader>Ministry Categories List</CardHeader>
                <CardBody>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>URL</th>
                                <th className="align-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ministryCategories.map((mc, index) => (
                                <tr key={mc.id}>
                                    <td>{index + 1}</td>
                                    <td>{mc.name}</td>
                                    <td>{mc.url}</td>
                                    <td className="align-right">
                                        <Button color="warning" onClick={() => goToForm(mc.id)} disabled={loading}>
                                            Edit
                                        </Button>{" "}
                                        <Button
                                            color="danger"
                                            onClick={() => openModal(mc)}
                                            disabled={loading}
                                        >
                                            Delete
                                            {loading ? <Spinner size="sm" color="light" /> : null}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <ConfirmationModal
                isOpen={modalOpen}
                toggle={cancelDeleteItem}
                onConfirm={confirmDeleteItem}
                title="Confirm Delete"
                message="Are you sure you want to delete this Ministry Category?"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </Container>
    );
}