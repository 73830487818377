import { FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import { ITranslationProps } from "../Language/ITranslationProps";
import useSubscribeTypeUpdate from "./useSubscribeTypeUpdate";

interface SubscribeTypeFormProps extends ITranslationProps{}

export const SubscribeTypeForm = (props: SubscribeTypeFormProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useSubscribeTypeUpdate(lang);

    return (
        <>
            <GenericForm title={lang ? langName : id ? 'Edit Subscriber Type' : 'Add Subscriber Type'} onSubmit={handleSubmit} loading={loading}>
                <>
                    <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            disabled={loading}
                        />
                        {formik.errors.name && <div className="text-danger">{formik.errors.name}</div>}
                    </FormGroup>
                </>
            </GenericForm>
        </>
    );
};
