import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../API";
import { ILanguage } from "./Model";

export const useLanguageList = () => {
  const { id } = useParams();
  const [langList, setLangList] = useState<ILanguage[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!id) return

    loadLanguageList();
  }, [id])

  const loadLanguageList = () => {
    setLoading(true);

    axios
        .get(`${API.LANGUAGE}`)
        .then((res: {data: ILanguage[]}) => setLangList(res.data.filter((lang) => lang.code !== 'en')))
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error('Error fetching language list!');
          setLoading(false);
        });
  }

  return { langList, loading };
}