import { FaTimes } from 'react-icons/fa';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { baseImagePath } from '../../API';
import GenericForm from '../../Component/GenericForm';
import { ITranslationProps } from '../Language/ITranslationProps';
import { useVisitUsUpdate } from './useVisitUsUpdate';

const VisitUsForm = (props: ITranslationProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useVisitUsUpdate(lang);

    return (
        <GenericForm
            title={lang ? langName : id ? 'Edit Visit Us' : 'Add Visit Us'}
            onSubmit={handleSubmit}
            loading={loading}
        >
            <>
                <FormGroup>
                    <Label>Name</Label>
                    <Input
                        type="text"
                        name="place.name"
                        onChange={formik.handleChange}
                        value={formik.values.place.name || ''}
                        invalid={formik.touched.place?.name && !!formik.errors.place?.name}
                        disabled={loading}
                    />
                    {formik.touched.place?.name && formik.errors.place?.name && (
                        <FormFeedback>{formik.errors.place?.name}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Location</Label>
                    <Input
                        type="text"
                        name="place.location"
                        onChange={formik.handleChange}
                        value={formik.values.place.location || ''}
                        invalid={formik.touched.place?.location && !!formik.errors.place?.location}
                        disabled={loading}
                    />
                    {formik.touched.place?.location && formik.errors.place?.location && (
                        <FormFeedback>{formik.errors.place?.location}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Map URL</Label>
                    <Input
                        type="text"
                        name="place.map_url"
                        onChange={formik.handleChange}
                        value={formik.values.place.map_url || ''}
                        invalid={formik.touched.place?.map_url && !!formik.errors.place?.map_url}
                        disabled={loading}
                    />
                    {formik.touched.place?.map_url && formik.errors.place?.map_url && (
                        <FormFeedback>{formik.errors.place?.map_url}</FormFeedback>
                    )}
                </FormGroup>
                {!lang && (
                    <FormGroup>
                        <Label>Image</Label>
                        {formik.values.place?.image_url && (
                            <div className="formImage">
                                <img
                                    src={`${baseImagePath}${formik.values.place?.image_url}`}
                                    alt="Visit Us Image"
                                />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('place.image_url', '');
                                        formik.setFieldValue('place.image', undefined);
                                        if (id) formik.setFieldValue('place.is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        {formik.values.place?.image && (
                            <div className="formImage">
                                <img
                                    src={URL.createObjectURL(formik.values.place?.image)}
                                    alt="Visit Us Image"
                                />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('place.image_url', '');
                                        formik.setFieldValue('place.image', undefined);
                                        if (id) formik.setFieldValue('place.is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        <Input
                            type="file"
                            name="place.image"
                            onChange={(event) => {
                                formik.setFieldValue('place.image_url', '');
                                formik.setFieldValue(
                                    'place.image',
                                    event.currentTarget.files ? event.currentTarget.files[0] : undefined
                                );
                                if (id) formik.setFieldValue('place.is_update_image', true);
                            }}
                            invalid={formik.touched.place?.image && !!formik.errors.place?.image}
                            disabled={loading}
                        />
                        {formik.touched.place?.image && formik.errors.place?.image && (
                            <FormFeedback>{formik.errors.place?.image}</FormFeedback>
                        )}
                    </FormGroup>
                )}

                <FormGroup id="email">
                    <Row>
                        <Col>
                            <Label>Email Label</Label>
                            <Input
                                type="text"
                                name="social_media.email.label"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.email?.label || ''}
                                invalid={
                                    formik.touched.social_media?.email?.label && !!formik.errors.social_media?.email?.label
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.email?.label &&
                                formik.errors.social_media?.email?.label && (
                                    <FormFeedback>{formik.errors.social_media?.email?.label}</FormFeedback>
                                )}
                        </Col>
                        <Col>
                            <Label>Email URL</Label>
                            <Input
                                type="text"
                                name="social_media.email.url"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.email?.url || ''}
                                invalid={
                                    formik.touched.social_media?.email?.url && !!formik.errors.social_media?.email?.url
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.email?.url && formik.errors.social_media?.email?.url && (
                                <FormFeedback>{formik.errors.social_media?.email?.url}</FormFeedback>
                            )}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup id="fb">
                    <Row>
                        <Col>
                            <Label>FB Label</Label>
                            <Input
                                type="text"
                                name="social_media.fb.label"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.fb?.label || ''}
                                invalid={
                                    formik.touched.social_media?.fb?.label &&
                                    !!formik.errors.social_media?.fb?.label
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.fb?.label &&
                                formik.errors.social_media?.fb?.label && (
                                    <FormFeedback>{formik.errors.social_media?.fb?.label}</FormFeedback>
                                )}
                        </Col>
                        <Col>
                            <Label>FB URL</Label>
                            <Input
                                type="text"
                                name="social_media.fb.url"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.fb?.url || ''}
                                invalid={
                                    formik.touched.social_media?.fb?.url &&
                                    !!formik.errors.social_media?.fb?.url
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.fb?.url &&
                                formik.errors.social_media?.fb?.url && (
                                    <FormFeedback>{formik.errors.social_media?.fb?.url}</FormFeedback>
                                )}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup id="ig">
                    <Row>
                        <Col>
                            <Label>IG Label</Label>
                            <Input
                                type="text"
                                name="social_media.ig.label"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.ig?.label || ''}
                                invalid={
                                    formik.touched.social_media?.ig?.label &&
                                    !!formik.errors.social_media?.ig?.label
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.ig?.label &&
                                formik.errors.social_media?.ig?.label && (
                                    <FormFeedback>{formik.errors.social_media?.ig?.label}</FormFeedback>
                                )}
                        </Col>
                        <Col>
                            <Label>IG URL</Label>
                            <Input
                                type="text"
                                name="social_media.ig.url"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.ig?.url || ''}
                                invalid={
                                    formik.touched.social_media?.ig?.url &&
                                    !!formik.errors.social_media?.ig?.url
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.ig?.url &&
                                formik.errors.social_media?.ig?.url && (
                                    <FormFeedback>{formik.errors.social_media?.ig?.url}</FormFeedback>
                                )}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup id="tiktok">
                    <Row>
                        <Col>
                            <Label>Tiktok Label</Label>
                            <Input
                                type="text"
                                name="social_media.tiktok.label"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.tiktok?.label || ''}
                                invalid={
                                    formik.touched.social_media?.tiktok?.label &&
                                    !!formik.errors.social_media?.tiktok?.label
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.tiktok?.label &&
                                formik.errors.social_media?.tiktok?.label && (
                                    <FormFeedback>{formik.errors.social_media?.tiktok?.label}</FormFeedback>
                                )}
                        </Col>
                        <Col>
                            <Label>Tiktok URL</Label>
                            <Input
                                type="text"
                                name="social_media.tiktok.url"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.tiktok?.url || ''}
                                invalid={
                                    formik.touched.social_media?.tiktok?.url &&
                                    !!formik.errors.social_media?.tiktok?.url
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.tiktok?.url &&
                                formik.errors.social_media?.tiktok?.url && (
                                    <FormFeedback>{formik.errors.social_media?.tiktok?.url}</FormFeedback>
                                )}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup id="youtube">
                    <Row>
                        <Col>
                            <Label>Youtube Label</Label>
                            <Input
                                type="text"
                                name="social_media.youtube.label"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.youtube?.label || ''}
                                invalid={
                                    formik.touched.social_media?.youtube?.label &&
                                    !!formik.errors.social_media?.youtube?.label
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.youtube?.label &&
                                formik.errors.social_media?.youtube?.label && (
                                    <FormFeedback>{formik.errors.social_media?.youtube?.label}</FormFeedback>
                                )}
                        </Col>
                        <Col>
                            <Label>Youtube URL</Label>
                            <Input
                                type="text"
                                name="social_media.youtube.url"
                                onChange={formik.handleChange}
                                value={formik.values.social_media.youtube?.url || ''}
                                invalid={
                                    formik.touched.social_media?.youtube?.url &&
                                    !!formik.errors.social_media?.youtube?.url
                                }
                                disabled={loading}
                            />
                            {formik.touched.social_media?.youtube?.url &&
                                formik.errors.social_media?.youtube?.url && (
                                    <FormFeedback>{formik.errors.social_media?.youtube?.url}</FormFeedback>
                                )}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Label>Contact</Label>
                    { formik.values.contact && formik.values.contact.map((contact, index) => (
                        <div key={index} className="d-flex align-items-center">
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label>Type</Label>
                                        <Input
                                            type="text"
                                            value={contact.type}
                                            onChange={(event) => {
                                                const newContacts = [...formik.values.contact];
                                                newContacts[index].type = event.target.value;
                                                formik.setFieldValue('contact', newContacts);
                                            }}
                                            disabled={loading}
                                        />
                                    </Col>
                                    <Col>
                                        <Label>Label</Label>
                                        <Input
                                            type="text"
                                            value={contact.label}
                                            onChange={(event) => {
                                                const newContacts = [...formik.values.contact];
                                                newContacts[index].label = event.target.value;
                                                formik.setFieldValue('contact', newContacts);
                                            }}
                                            disabled={loading}
                                        />
                                    </Col>
                                    <Col>
                                        <Label>URL</Label>
                                        <Input
                                            type="text"
                                            value={contact.url}
                                            onChange={(event) => {
                                                const newContacts = [...formik.values.contact];
                                                newContacts[index].url = event.target.value;
                                                formik.setFieldValue('contact', newContacts);
                                            }}
                                            disabled={loading}
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            onClick={() => {
                                                const newContacts = [...formik.values.contact];
                                                newContacts.splice(index, 1);
                                                formik.setFieldValue('contact', newContacts);
                                            }}
                                            className="ml-2"
                                            disabled={loading}
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </div>
                    ))}
                    <Button
                        color="primary"
                        onClick={() => {
                            formik.setFieldValue('contact', [
                                ...formik.values.contact,
                                {
                                    type: '',
                                    label: '',
                                    url: '',
                                },
                            ]);
                        }}
                        disabled={loading}
                    >
                        Add Contact
                    </Button>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={1} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>  
                            <Input
                                type="checkbox"
                                name="active"
                                onChange={(e) => {
                                    formik.setFieldValue('active', e.target.checked ? 1 : 0);
                                }}
                                invalid={formik.touched.active && !!formik.errors.active}
                                disabled={loading}
                                checked={formik.values.active ? true : false }
                            />
                        </Col>
                        <Col>
                            Active
                        </Col>
                    </Row>
                </FormGroup>
            </>
        </GenericForm>
    );
};

export default VisitUsForm;
