import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";

const TopNavMenuData = [
    { title: 'Slides', to: '/slides' },
    { title: 'Services', to: '/services' },
    { title: 'Leaders', to: '/leaders' },
    { title: 'Visit Us', to: '/visit-us' },
    { title: 'Ministries', to: '/ministries' },
    { title: 'Events', to: '/events' },
    { title: 'Medias', to: '/medias' },
    { title: 'Gives', to: '/gives' },
    { title: 'Links', to: '/links' }
]

const TopNavMenuAdmin = [
    { title: 'Users', to: '/users' },
    { title: 'Access Controls', to: '/access-controls' },
    { title: 'Ministry Categories', to: '/ministry-categories' },
    { title: 'Subscribe Types', to: '/subscribe-types' },
    { title: 'Email Setting', to: '/email-setting' },
    { title: 'Languages', to: '/languages' }
]

const TopNavMenuAccount = [
    { title: 'Change Password', to: '/change-password' },
    { title: 'Logout', to: '/logout' }
]

export const TopNavMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDataDropdownOpen, setIsDataDropdownOpen] = useState(false);
    const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);

    const location = useLocation();
    console.log(location.pathname)

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const toggleDataDropdown = () => {
        setIsDataDropdownOpen(!isDataDropdownOpen);
    }

    const toggleAdminDropdown = () => {
        setIsAdminDropdownOpen(!isAdminDropdownOpen);
    }

    const toggleAccountDropdown = () => {
        setIsAccountDropdownOpen(!isAccountDropdownOpen);
    }

    return (
        <Navbar color='light' className='my-2' expand="md">
            <NavbarBrand href="/">Gilgal Frontend - Backend</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="me-auto" navbar>
                    <Dropdown nav isOpen={isDataDropdownOpen} toggle={toggleDataDropdown}>
                        <DropdownToggle nav caret>
                            Data
                        </DropdownToggle>
                        <DropdownMenu>
                            <Nav>
                            {
                                TopNavMenuData.map((item, index) => (
                                    <NavItem key={index} {...location.pathname == item.to ? {active: true} : {}}>
                                        <NavLink to={item.to}>{item.title}</NavLink>
                                    </NavItem>
                                ))
                            }
                            </Nav>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown nav isOpen={isAdminDropdownOpen} toggle={toggleAdminDropdown}>
                        <DropdownToggle nav caret>
                            Admin
                        </DropdownToggle>
                        <DropdownMenu>
                            <Nav>
                            {
                                TopNavMenuAdmin.map((item, index) => (
                                    <NavItem key={index} {...location.pathname == item.to ? {active: true} : {}}>
                                        <NavLink to={item.to}>{item.title}</NavLink>
                                    </NavItem>
                                ))
                            }
                            </Nav>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown nav isOpen={isAccountDropdownOpen} toggle={toggleAccountDropdown}>
                        <DropdownToggle nav caret>
                            Account
                        </DropdownToggle>
                        <DropdownMenu>
                            <Nav>
                            {
                                TopNavMenuAccount.map((item, index) => (
                                    <NavItem key={index} {...location.pathname == item.to ? {active: true} : {}}>
                                        <NavLink to={item.to}>{item.title}</NavLink>
                                    </NavItem>
                                ))
                            }
                            </Nav>
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </Collapse>
        </Navbar>
    )
}