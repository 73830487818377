import React from 'react';
import { Spinner } from 'reactstrap';
import { useLanguageList } from '../Language/useLanguageList';
import ServiceForm from './ServiceForm';

const ServiceUpdate: React.FC = () => {
    const { loading, langList } = useLanguageList()

    return (
        <>
            <ServiceForm />
            {loading ? (<div>Loading language list <Spinner size="sm" color="dark" /></div>) : ''}
            {langList.map((lang) =>
                <ServiceForm lang={lang.code} langName={lang.name} key={lang.code} />
            )}
        </>
    );
};

export default ServiceUpdate;
