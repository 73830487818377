import { Spinner } from "reactstrap";
import { useLanguageList } from "../Language/useLanguageList";
import GiveForm from "./GiveForm";

const GiveUpdate: React.FC = () => {
    const { loading, langList } = useLanguageList()

    return (
        <>
            <GiveForm />
            {loading ? (<div>Loading language list <Spinner size="sm" color="dark" /></div>) : ''}
            {langList.map((lang) =>
                <GiveForm lang={lang.code} langName={lang.name} key={lang.code} />
            )}
        </>
    );
};

export default GiveUpdate;
