export enum MediaCategory {
    Sermons = "sermons",
    Originals = "originals",
    Worship = "worship"
}

export enum LinkType {
    Youtube = "youtube",
    Other = "other"
}

export enum DisplayType {
    NewRelease = "new_release",
    Shorts = "shorts",
    Recent = "recent",
    Podcast = "podcast"
}

export interface IMediaItem {
    title: string
    description: string
    url: string
    image_url?: string
}

export interface IMedia {
    id?: number
    category: string
    section: string
    link_type: string
    display_type: string
    items: IMediaItem[]
    max_items: number
    view_more_url?: string
    order?: number
}