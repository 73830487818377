import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { IMinistryCategory } from './Model';

const MinistryCategorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    url: Yup.string().required('Url is required'),
});

export const useMinistryCategoryUpdate = (lang?: string) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ministryCategory, setMinistryCategory] = useState<IMinistryCategory>({
        name: '',
        url: ''
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            axios
                .get(`${API.MINISTRY_CATEGORY}${id}${lang ? `?lang=${lang}` : ''}`)
                .then(res => setMinistryCategory({
                    ...res.data,
                    ...(lang ? { lang: lang } : {})
                }))
                .catch(err => toast.error('Error fetching ministry category!'))
                .finally(() => setLoading(false));
        }
    }, [id])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: ministryCategory,
        validationSchema: MinistryCategorySchema,
        onSubmit: (values) => {
            setLoading(true);
            const apiUrl = id ? `${API.MINISTRY_CATEGORY}${id}` : API.MINISTRY_CATEGORY;
            const successMessage = id ? 'Ministry Category updated successfully!' : 'Ministry Category created successfully!';
            const errorMessage = id ? 'Error updating Ministry Category!' : 'Error creating Ministry Category!';

            axios
                .request({
                    method: id ? 'put' : 'post',
                    url: apiUrl,
                    data: values
                })
                .then(() => {
                    toast.success(successMessage);
                    navigate('/ministry-categories');
                })
                .finally(() => setLoading(false))
                .catch(() => {
                    toast.error(errorMessage);
                    setLoading(false);
                });
        }
    });

    const handleSubmit = formik.handleSubmit;

    return { id, loading, formik, handleSubmit };
}