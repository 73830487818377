import { toast } from "react-toastify"

export enum ErrorOnAction {
    Fetch = 'fetching',
    Create = 'creating',
    Update = 'updating'
}

const ShowErrorToast = (err: any, section?: string, action?: ErrorOnAction) => {
    toast.error(`Error ${action} ${section} with error message: ${err.response.data.message || ''}`)
}

export default ShowErrorToast