import { FaTimes } from 'react-icons/fa';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { baseImagePath } from '../../API';
import GenericForm from '../../Component/GenericForm';
import { ITranslationProps } from "../Language/ITranslationProps";
import { useGiveUpdate } from './useGiveUpdate';

const GiveForm = (props: ITranslationProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useGiveUpdate(lang);

    return (
        <GenericForm
            title={lang ? langName : id ? 'Edit Give' : 'Add Give'}
            onSubmit={handleSubmit}
            loading={loading}
            id={id} multipleLang={true}
        >
            <>
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        type="text"
                        name="type"
                        onChange={formik.handleChange}
                        value={formik.values.type || ''}
                        invalid={formik.touched.type && !!formik.errors.type}
                        disabled={loading}
                    />
                    {formik.touched.type && formik.errors.type && (
                        <FormFeedback>{formik.errors.type}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Title</Label>
                    <Input
                        type="text"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title || ''}
                        invalid={formik.touched.title && !!formik.errors.title}
                        disabled={loading}
                    />
                    {formik.touched.title && formik.errors.title && (
                        <FormFeedback>{formik.errors.title}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Account</Label>
                    <Input
                        type="text"
                        name="account"
                        onChange={formik.handleChange}
                        value={formik.values.account || ''}
                        invalid={formik.touched.account && !!formik.errors.account}
                        disabled={loading}
                    />
                    {formik.touched.account && formik.errors.account && (
                        <FormFeedback>{formik.errors.account}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Account Number</Label>
                    <Input
                        type="text"
                        name="account_number"
                        onChange={formik.handleChange}
                        value={formik.values.account_number || ''}
                        invalid={formik.touched.account_number && !!formik.errors.account_number}
                        disabled={loading}
                    />
                    {formik.touched.account_number && formik.errors.account_number && (
                        <FormFeedback>{formik.errors.account_number}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Input
                        type="text"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description || ''}
                        invalid={formik.touched.description && !!formik.errors.description}
                        disabled={loading}
                    />
                    {formik.touched.description && formik.errors.description && (
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                    )}
                </FormGroup>
                {!lang &&
                    <FormGroup>
                        <Label>Image</Label>
                        {formik.values.image_url && (
                            <div className="formImage">
                                <img src={`${baseImagePath}${formik.values.image_url}`} alt="Give Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);

                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        {formik.values.image && (
                            <div className="formImage">
                                <img
                                    src={URL.createObjectURL(formik.values.image)}
                                    alt="Give Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);

                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        <Input
                            type="file"
                            name="image"
                            onChange={event => {
                                formik.setFieldValue('image_url', '');
                                formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : undefined);
                                if (id) formik.setFieldValue('is_update_image', true);
                            }}
                            invalid={formik.touched.image && !!formik.errors.image}
                            disabled={loading}
                        />
                        {formik.touched.image && formik.errors.image && <FormFeedback>{formik.errors.image}</FormFeedback>}
                    </FormGroup>
                }
            </>
        </GenericForm>
    );
};

export default GiveForm;
