import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { API } from "../../API";

interface IChangePassword {
    old_password: string
    new_password: string
    retry_password: string
}

const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string().required('New Password is required'),
    retry_password: Yup.string().required('Retype Password is required').equals([Yup.ref('new_password')], 'Passwords must match')
});

export const useChangePasswordUpdate = () => {
    const [changePassword, setChangePassword] = useState<IChangePassword>({
        old_password: '',
        new_password: '',
        retry_password: ''
    });

    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: changePassword,
        validationSchema: ChangePasswordSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            const successMessage = 'Password changed successfully!';
            const errorMessage = 'Error changing password: ';

            axios.post(`${API.CHANGE_PASSWORD}`, values)
            .then(() => {
                toast.success(successMessage);
                resetForm()
            })
            .catch((e) => {
                toast.error(errorMessage + e.response.data.error + "!");
            })
            .finally(() => {
                setLoading(false);
            });
        }
    });

    const handleSubmit = formik.handleSubmit

    return { loading, formik, handleSubmit }
}