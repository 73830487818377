import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import { API } from '../../API';
import ConfirmationModal from '../../Component/ConfirmationModal';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { useModal } from '../../Hook/useModal';
import { ISubscribeType } from './Model';

const SubscribeType: React.FC = () => {
    const [subscribeTypes, setSubscribeTypes] = useState<ISubscribeType[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { modalOpen, data, openModal, closeModal } = useModal();

    useEffect(() => {
        axios
            .get(API.SUBSCRIBE_TYPE)
            .then(res => setSubscribeTypes(res.data))
            .catch(err => ShowErrorToast(err, "Subscribe Type", ErrorOnAction.Fetch));
    }, []);

    const confirmDeleteSubscribeType = () => {
        if (data !== null && data.id !== undefined) {
            setLoading(true);
            axios
                .delete(`${API.SUBSCRIBE_TYPE}${data.id}`)
                .then(() => {
                    toast.success('Subscribe Type deleted successfully!');
                    setSubscribeTypes(subscribeTypes.filter(subscribeType => subscribeType.id !== data.id));
                })
                .finally(() => setLoading(false))
                .catch(err => {
                    toast.error('Error deleting Subscribe Type!');
                    setLoading(false);
                });
        }
        closeModal();
    };

    const cancelDeleteSubscribeType = () => {
        closeModal();
    };

    const goToForm = (id?: number) => {
        navigate(`/subscribe-types/form${id ? `/${id}` : ''}`);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Subscribe Types</h1>
                </Col>
                <Col className="align-right">
                    <Button color="primary" onClick={() => goToForm()}>
                        Add New
                    </Button>
                </Col>
            </Row>
            <Row className="separator" />
            <Row>
                <Col>
                    <Card>
                        <CardHeader>Subscribe Type List</CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th className="align-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscribeTypes.map((subscribeType, index) => (
                                        <tr key={subscribeType.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{subscribeType.name}</td>
                                            <td className="align-right">
                                                <Button color="warning" onClick={() => goToForm(subscribeType.id)} disabled={loading}>
                                                    Edit
                                                </Button>{" "}
                                                <Button color="danger" onClick={() => openModal(subscribeType)} disabled={loading}>
                                                    Delete
                                                    {loading ? <Spinner size="sm" color="light" /> : null}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal
                isOpen={modalOpen}
                toggle={cancelDeleteSubscribeType}
                onConfirm={confirmDeleteSubscribeType}
                title="Confirm Delete"
                message="Are you sure you want to delete this Subscribe Type?"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </Container>
    );
};

export default SubscribeType;
