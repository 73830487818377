import { FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import { ITranslationProps } from "../Language/ITranslationProps";
import useSlideUpdate from "./useSlideUpdate";

export const SlideForm = (props: ITranslationProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useSlideUpdate(lang);

    return (
        <>
            <GenericForm title={ lang ? langName : id ? 'Edit Slide' : 'Add Slide'} onSubmit={handleSubmit} loading={loading}>
                <>
                    <FormGroup>
                        <Label htmlFor="content">Content</Label>
                        <Input
                            id="content"
                            name="content"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.content}
                            disabled={loading}
                        />
                        {formik.errors.content && <div className="text-danger">{formik.errors.content}</div>}
                    </FormGroup>
                </>
            </GenericForm>
        </>
    );
};