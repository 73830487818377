import React from 'react';
import { Spinner } from 'reactstrap';
import { useLanguageList } from '../Language/useLanguageList';
import LeaderForm from './LeaderForm';

const LeaderUpdate: React.FC = () => {
    const { loading, langList } = useLanguageList()

    return (
        <>
            <LeaderForm />
            {loading ? (<div>Loading language list <Spinner size="sm" color="dark" /></div>) : ''}
            {langList.map((lang) =>
                <LeaderForm lang={lang.code} langName={lang.name} key={lang.code} />
            )}
        </>
    )
};

export default LeaderUpdate;
