import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { API } from "../../API";
import { useLanguageList } from "../Language/useLanguageList";
import { IMinistryCategory } from "../MinistryCategory/Model";
import { ISubscribeType } from "../SubscribeType/Model";
import MinistryForm from "./MinistryForm";

const MinistryUpdate = () => {
    const [ministryCategories, setMinistryCategories] = useState<IMinistryCategory[]>([]);
    const [subscribeTypes, setSubscribeTypes] = useState<ISubscribeType[]>([])
    const [loadingMC, setLoadingMC] = useState<boolean>(false);
    const [loadingST, setLoadingST] = useState<boolean>(false);
    const { loading, langList } = useLanguageList()

    useEffect(() => {
        setLoadingMC(true);
        setLoadingST(true);
        axios
            .get(API.MINISTRY_CATEGORY)
            .then(res => setMinistryCategories(res.data))
            .finally(() => setLoadingMC(false))
            .catch(err => toast.error('Error fetching ministry categories!'));

        axios
            .get(API.SUBSCRIBE_TYPE)
            .then(res => setSubscribeTypes(res.data))
            .finally(() => setLoadingST(false))
            .catch(err => toast.error('Error fetching subscribe type!'));
    }, []);

    if (loadingMC || loadingST) return (<>
        <Spinner color="light" /> Loading Ministry Categories and Subscribe Type
    </>
    );

    return (
        <>
            <MinistryForm ministryCategories={ministryCategories} subscribeTypes={subscribeTypes} />
            {loading ? (<div>Loading language list <Spinner size="sm" color="dark" /></div>) : ''}
            {langList.map((lang) =>
                <MinistryForm lang={lang.code} langName={lang.name} key={lang.code}
                    ministryCategories={ministryCategories}
                    subscribeTypes={subscribeTypes}
                />
            )}
        </>
    )
}

export default MinistryUpdate;