import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from "reactstrap";
import { API } from "../../API";
import ConfirmationModal from "../../Component/ConfirmationModal";
import { useModal } from "../../Hook/useModal";
import { IMedia } from "./Model";

export const Media = () => {
    const [medias, setMedias] = useState<IMedia[]>([]);
    const [maxOrderNumbers, setMaxOrderNumbers] = useState<{ [category: string]: number }>({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { modalOpen, data, openModal, closeModal } = useModal();

    useEffect(() => {
        getAllMedias();
    }, []);

    const getAllMedias = () => {
        axios
            .get(API.MEDIA)
            .then(res => {
                setMedias(res.data);
                // Calculate the max order number for each category
                const maxOrderNumbers: { [category: string]: number } = {};
                res.data.forEach((media: IMedia) => {
                    if(!media.order) return;
                    if (!maxOrderNumbers[media.category] || media.order > maxOrderNumbers[media.category]) {
                        maxOrderNumbers[media.category] = media.order;
                    }
                });
                setMaxOrderNumbers(maxOrderNumbers);
            })
            .catch(err => toast.error('Error fetching media!'));
    }

    const confirmDeleteItem = () => {
        if (data !== null && data.id !== undefined) {
            setLoading(true);
            axios
                .delete(`${API.MEDIA}${data.id}`)
                .then(() => {
                    toast.success('Media deleted successfully!');
                    setMedias(medias.filter(media => media.id !== data.id));
                })
                .finally(() => setLoading(false))
                .catch(err => {
                    toast.error('Error deleting media!');
                    setLoading(false);
                });
        }
        closeModal();
    };

    const changeOrder = (id: number | undefined, order?: number) => {
        if(id === undefined || order === undefined) return;
        setLoading(true);
        axios
            .put(`${API.MEDIA}${id}/change-order`, { order })
            .then(() => {
                toast.success('Order changed successfully!');
                setMedias([]);
                getAllMedias()
            })
            .finally(() => setLoading(false))
            .catch(err => {
                toast.error('Error changing order!');
                setLoading(false);
            });
    };

    const cancelDeleteItem = () => {
        closeModal();
    };

    const goToForm = (id?: number) => {
        navigate(`/medias/form${id ? `/${id}` : ''}`);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Medias</h1>
                </Col>
                <Col className="align-right">
                    <Button color="primary" onClick={() => goToForm()}>
                        Add New
                    </Button>
                </Col>
            </Row>

            <Card className="mt-4">
                <CardHeader>Media List</CardHeader>
                <CardBody>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category</th>
                                <th>Display Type</th>
                                <th>Section Name</th>
                                <th>Order</th>
                                <th className="align-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {medias.map((media, index) => (
                                <tr key={media.id}>
                                    <td>{index + 1}</td>
                                    <td>{media.category}</td>
                                    <td>{media.display_type}</td>
                                    <td>{media.section}</td>
                                    <td>{media.order}</td>
                                    <td className="align-right">
                                        <Button color="warning" onClick={() => goToForm(media.id)} disabled={loading}>Edit</Button>{" "}
                                        <Button color="danger" onClick={() => openModal(media)} disabled={loading}>Delete</Button>
                                        <Button color="primary" onClick={() => changeOrder(media.id, media.order ? media.order - 1 : 1)} disabled={loading || media.order === 1}>Up</Button>
                                        <Button color="primary" onClick={() => changeOrder(media.id, media.order ? media.order + 1 : 1)} disabled={loading || media.order === maxOrderNumbers[media.category]}>Down</Button>
                                        {loading ? <Spinner size="sm" color="light" /> : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <ConfirmationModal
                isOpen={modalOpen}
                toggle={cancelDeleteItem}
                onConfirm={confirmDeleteItem}
                title="Confirm Delete"
                message="Are you sure you want to delete this media?"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </Container>
    );
}
