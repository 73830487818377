import React from 'react';
import VisitUsForm from './VisitUsForm';

const VisitUsUpdate: React.FC = () => {
    return (
        <>
            <VisitUsForm />
        </>
    );
};

export default VisitUsUpdate;
