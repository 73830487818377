import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import { API } from '../../API';
import ConfirmationModal from '../../Component/ConfirmationModal';
import { useModal } from '../../Hook/useModal';
import { NoWheel } from '../../Helper/NoWheel';

export interface ItemOrder{
  id: number
  order: number
}

export const Service = () => {
  const [services, setServices] = useState<any[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { modalOpen, data, openModal, closeModal } = useModal();

  const loadData = () => {
    axios
      .get(API.SERVICE)
      .then(res => setServices(res.data))
      .catch(err => toast.error('Error fetching services!'));
  }
  useEffect(() => {
    loadData();
  }, []);

  const confirmDeleteItem = () => {
    if (data !== null && data.id !== undefined) {
      setLoading(true);
      axios
        .delete(`${API.SERVICE}${data.id}`)
        .then(() => {
          toast.success('Service deleted successfully!');
          setServices(services.filter(service => service.id !== data.id));
        })
        .finally(() => setLoading(false))
        .catch(err => {
          toast.error('Error deleting service!');
          setLoading(false);
        });
    }
    closeModal();
  };

  const cancelDeleteItem = () => {
    closeModal();
  };

  const goToForm = (id?: number) => {
    navigate(`/services/form${id ? `/${id}` : ''}`);
  };

  const onServiceOrderChange = (e: any, service: any) => {
    const newServices = [...services];
    const index = newServices.findIndex(s => s.id === service.id);
    newServices[index].order = e.target.value;
    setServices(newServices);
  }

  const getServiceItemOrder = () => {
    const newServices = [...services];
    const newItems: ItemOrder[] = [];
    newServices.forEach(service => {
      newItems.push({
        id: service.id,
        order: service.order
      });
    });
    return newItems;
  }

  const onServiceOrderSave = () => {
    setLoading(true);
    axios
      .post(API.SERVICE + "set-order", {orders: getServiceItemOrder()})
      .then(res => {
        toast.success('Service order saved successfully!');
        loadData();
      })
      .finally(() => setLoading(false))
      .catch(err => {
        toast.error('Error saving service order!');
        setLoading(false);
      });
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Services</h1>
        </Col>
        <Col className="align-right">
          <Button color="primary" onClick={() => goToForm()}>
            Add New
          </Button>
        </Col>
      </Row>

      <Card className="mt-4">
        <CardHeader>Service List</CardHeader>
        <CardBody>
          <Table striped responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Order</th>
                <th>Type</th>
                <th>Title</th>
                <th>Description</th>
                <th className="align-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={service.id}>
                  <td>{index + 1}</td>
                  <td><input type="number" onWheel={NoWheel} min={0} value={service.order ?? 0} style={{width: "40px"}} 
                      onChange={(e) => onServiceOrderChange(e, service)}
                    /></td>
                  <td>{service.type}</td>
                  <td>{service.title}</td>
                  <td>{service.description}</td>
                  <td className="align-right">
                    <Button color="warning" onClick={() => goToForm(service.id)} disabled={loading}>
                      Edit
                    </Button>{" "}
                    <Button
                      color="danger"
                      onClick={() => openModal(service)}
                      disabled={loading}
                    >
                      Delete
                      {loading ? <Spinner size="sm" color="light" /> : null}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter style={{textAlign: "right"}}>
          <Button color="primary" onClick={onServiceOrderSave}>
            Update Order
          </Button>
        </CardFooter>
      </Card>
      <ConfirmationModal
        isOpen={modalOpen}
        toggle={cancelDeleteItem}
        onConfirm={confirmDeleteItem}
        title="Confirm Delete"
        message="Are you sure you want to delete this service?"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </Container>
  );
};
