import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { API } from "../../API";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate()

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        axios
            .post(`${API.LOGIN}`, { email, password })
            .then(res => {
                sessionStorage.setItem("token", res.data.token);
                toast.success('Login successful!');
                navigate('/', {
                    replace: true
                })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toast.error('Wrong Authentication Credentials!');
                } else {
                    toast.error('Error logging in!');
                }
            });
    };

    return (
        <Container style={{
            maxWidth: "500px",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginTop: "20px"
        }}>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </FormGroup>
                <FormGroup>
                    <Label for="password">Password</Label>
                    <Input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </FormGroup>
                <Button color="primary" type="submit">Login</Button>
            </Form>
        </Container>

    );
};

export const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token) {
            sessionStorage.removeItem("token");
            // axios post logout
            axios.post(`${API.LOGOUT}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    toast.success('Logout successful!');
                    navigate('/login', {
                        replace: true
                    })
                })
                .catch(err => {
                    toast.error('Error logging out!');
                });
        }
    }, [])

    return <></>
}