import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TopNavMenu } from './Component/TopNavMenu';
import AccessControl from './Modules/AccessControl/AccessControl';
import AccessControlUpdate from './Modules/AccessControl/AccessControlUpdate';
import { EmailSetting } from './Modules/EmailSetting/EmailSetting';
import { Event } from './Modules/Event/Event';
import EventUpdate from './Modules/Event/EventUpdate';
import { Give } from './Modules/Give/Give';
import GiveUpdate from './Modules/Give/GiveUpdate';
import { Language } from './Modules/Language/Language';
import LanguageUpdate from './Modules/Language/LanguageUpdate';
import { Leader } from './Modules/Leader/Leader';
import LeaderUpdate from './Modules/Leader/LeaderUpdate';
import { Links } from './Modules/Links/Link';
import { ChangePassword } from './Modules/Login/ChangePassword';
import { Login, Logout } from './Modules/Login/Login';
import { Media } from './Modules/Media/Media';
import MediaUpdate from './Modules/Media/MediaUpdate';
import { Ministry } from './Modules/Ministry/Ministry';
import MinistryUpdate from './Modules/Ministry/MinistryUpdate';
import { MinistryCategory } from './Modules/MinistryCategory/MinistryCategory';
import { MinistryCategoryUpdate } from './Modules/MinistryCategory/MinistryCategoryUpdate';
import { Service } from './Modules/Service/Service';
import ServiceUpdate from './Modules/Service/ServiceUpdate';
import Slide from './Modules/Slide/Slide';
import SlideUpdate from './Modules/Slide/SlideUpdate';
import SubscribeType from './Modules/SubscribeType/SubscribeType';
import SubscribeTypeUpdate from './Modules/SubscribeType/SubscribeTypeUpdate';
import User from './Modules/User/User';
import UserUpdate from './Modules/User/UserUpdate';
import { VisitUs } from './Modules/VisitUs/VisitUs';
import VisitUsUpdate from './Modules/VisitUs/VisitUsUpdate';


const App: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [token, setToken] = React.useState<string | null>(null);
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setToken(token);

    if(token === undefined || token === null) {
      navigate('/login')
    }

    if(location.pathname === '/login' && token !== null) {
      navigate('/')
    }
  }, [location.pathname])

  return (
    <>
      { token && <TopNavMenu /> }
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/slides" element={<Slide />} />
        <Route path="/slides/form/:id?" element={<SlideUpdate />} />
        <Route path="/services" element={<Service />} />
        <Route path="/services/form/:id?" element={<ServiceUpdate />} />
        <Route path="/leaders" element={<Leader />} />
        <Route path="/leaders/form/:id?" element={<LeaderUpdate />} />
        <Route path="/languages" element={<Language />} />
        <Route path="/languages/form/:id?" element={<LanguageUpdate />} />
        <Route path="/visit-us" element={<VisitUs />} />
        <Route path="/visit-us/form/:id?" element={<VisitUsUpdate />} />
        <Route path="/ministry-categories" element={<MinistryCategory />} />
        <Route path="/ministry-categories/form/:id?" element={<MinistryCategoryUpdate />} />
        <Route path="/ministries" element={<Ministry />} />
        <Route path='/ministries/form/:id?' element={<MinistryUpdate />} />
        <Route path='/events' element={<Event />} />
        <Route path='/events/form/:id?' element={<EventUpdate />} />
        <Route path='/medias' element={<Media />} />
        <Route path='/medias/form/:id?' element={<MediaUpdate />} />
        <Route path='/access-controls' element={<AccessControl />} />
        <Route path='/access-controls/form/:id?' element={<AccessControlUpdate />} />
        <Route path='/users' element={<User />} />
        <Route path='/users/form/:id?' element={<UserUpdate />} />
        <Route path='/gives' element={<Give />} />
        <Route path='/gives/form/:id?' element={<GiveUpdate />} />
        <Route path='/subscribe-types' element={<SubscribeType />} />
        <Route path='/subscribe-types/form/:id?' element={<SubscribeTypeUpdate />} />
        <Route path='/email-setting' element={<EmailSetting />} />
        <Route path='/links' element={<Links />} />
        <Route path='/change-password' element={<ChangePassword />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
