import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { ILanguage } from './Model';

const LanguageSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
});

export const useLanguageUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<ILanguage>({
    name: '',
    code: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.LANGUAGE}${id}`)
        .then(res => setLanguage(res.data))
        .catch(err => toast.error('Error fetching language!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: language,
    validationSchema: LanguageSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      if (id) {
        axios
          .put(`${API.LANGUAGE}${id}`, values)
          .then(res => {
            toast.success('Language updated successfully!');
            navigate('/languages');
          })
          .catch(err => toast.error('Error updating language!'))
          .finally(() => setLoading(false));
      } else {
        axios
          .post(API.LANGUAGE, values)
          .then(res => {
            toast.success('Language created successfully!');
            navigate('/languages');
          })
          .catch(err => toast.error('Error creating language!'))
          .finally(() => setLoading(false));
      }

      setSubmitting(false);
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { id, language, loading, formik, handleSubmit };
};
