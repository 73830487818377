import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { IGive } from './Model';

const GiveSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  title: Yup.string().required('Title is required'),
  account: Yup.string().required('Account is required'),
  account_number: Yup.string().required('Account Number is required'),
});

export const useGiveUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [give, setGive] = useState<IGive>({
    id: undefined,
    type: '',
    title: '',
    image: undefined,
    image_url: '',
    is_update_image: false,
    description: '',
    account: '',
    account_number: '',
    lang: lang,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.GIVE}${id}${lang ? `?lang=${lang}` : ''}`)
        .then((res) =>
          setGive({
            ...res.data,
            ...(lang ? { lang: lang } : {}),
          })
        )
        .catch((err) => toast.error('Error fetching give!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: give,
    validationSchema: GiveSchema,
    onSubmit: (values, { setSubmitting }) => {
      let formData = new FormData();
      if (id) {
        formData.append('_method', 'PUT');
      }
      Object.keys(values).forEach((key) => {
        const d = values as any;
        if (d[key] instanceof Array) {
          d[key].forEach((item: any) => {
            formData.append(key + '[]', item);
          });
          return;
        }
        formData.append(key, d[key]);
      });

      setLoading(true);
      if (id) {
        axios
          .post(`${API.GIVE}${id}`, formData)
          .then((res) => {
            toast.success('Give updated successfully!');
          })
          .catch((err) => toast.error('Error updating give!'))
          .finally(() => setLoading(false));
      } else {
        axios
          .post(API.GIVE, formData)
          .then((res) => {
            toast.success('Give created successfully!');
            navigate('/gives/form/' + res.data.id, { replace: true });
          })
          .catch((err) => toast.error('Error creating give!'))
          .finally(() => setLoading(false));
      }

      setSubmitting(false);
    },
  });

  const handleSubmit = formik.handleSubmit;
  return { id, loading, formik, handleSubmit };
};

export default useGiveUpdate;
