import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import { API } from '../../API';
import ConfirmationModal from '../../Component/ConfirmationModal';
import { useModal } from '../../Hook/useModal';
import { ISlide } from './Model';

const Slides: React.FC = () => {
  const [slides, setSlides] = useState<ISlide[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { modalOpen, data, openModal, closeModal } = useModal();

  useEffect(() => {
    axios
      .get(API.SLIDE)
      .then(res => setSlides(res.data))
      .catch(err => toast.error('Error fetching slides!'));
  }, []);

  const confirmDeleteSlide = () => {
    if (data !== null && data.id !== undefined) {
      setLoading(true);
      axios
        .delete(`${API.SLIDE}${data.id}`)
        .then(() => {
          toast.success('Slide deleted successfully!');
          setSlides(slides.filter(slide => slide.id !== data.id));
        })
        .finally(() => setLoading(false))
        .catch(err => {
          toast.error('Error deleting slide!');
          setLoading(false);
        });
    }
    closeModal();
  };

  const cancelDeleteSlide = () => {
    closeModal();
  };

  const goToForm = (id?: number) => {
    navigate(`/slides/form${id ? `/${id}` : ''}`);
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>Slides</h1>
        </Col>
        <Col className="align-right">
          <Button color="primary" onClick={() => goToForm()}>
            Add New
          </Button>
        </Col>
      </Row>
      <Row className="separator" />
      <Row>
        <Col>
          <Card>
            <CardHeader>Slide List</CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Content</th>
                    <th className="align-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {slides.map((slide, index) => (
                    <tr key={slide.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{slide.content}</td>
                      <td className="align-right">
                        <Button color="warning" onClick={() => goToForm(slide.id)} disabled={loading}>
                          Edit
                        </Button>{" "}
                        <Button color="danger" onClick={() => openModal(slide)} disabled={loading}>
                          Delete
                          {loading ? <Spinner size="sm" color="light" /> : null}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        isOpen={modalOpen}
        toggle={cancelDeleteSlide}
        onConfirm={confirmDeleteSlide}
        title="Confirm Delete"
        message="Are you sure you want to delete this slide?"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </Container>
  );
};

export default Slides;
