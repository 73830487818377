import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { ISlide } from './Model';

const SlideSchema = Yup.object().shape({
  content: Yup.string().required('Required')
});

const useSlideUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [slide, setSlide] = useState<ISlide>({
    id: undefined,
    content: '',
    lang: lang
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.SLIDE}${id}${lang ? `?lang=${lang}` : ''}`)
        .then((res) => setSlide({
          ...res.data,
          ...(lang ? { lang: lang } : {})
        }))
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error('Error fetching slide!');
          setLoading(false);
        });
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: slide,
    validationSchema: SlideSchema,
    onSubmit: (values) => {
      setLoading(true);
      const apiUrl = id ? `${API.SLIDE}${id}` : API.SLIDE;
      const successMessage = id ? 'Slide updated successfully!' : 'Slide created successfully!';
      const errorMessage = id ? 'Error updating slide!' : 'Error creating slide!';

      axios
        .request({
          method: id ? 'put' : 'post',
          url: apiUrl,
          data: values
        })
        .then(() => {
          toast.success(successMessage);
          navigate('/slides');
        })
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error(errorMessage);
          setLoading(false);
        });
    }
  });

  const handleSubmit = formik.handleSubmit;

  return { id, slide, loading, formik, handleSubmit };
};

export default useSlideUpdate;
