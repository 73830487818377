export enum PageType {
    HOME = 'home',
    GET_TO_KNOW_US = 'get_to_know_us',
}

export enum LeaderType {
    Leader1 = "leader1",
    Leader2 = "leader2",
}

export interface ILeader {
    name: string
    title: string
    long_description: string
    image_url?: string
    image?: File
    is_update_image?: boolean
    lang?: string
    page: PageType
    leader_type: LeaderType
}