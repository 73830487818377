import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { IUser } from './Model';

const UserSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required')
});

const useUserUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>({
    id: undefined,
    name: '',
    email: '',
    password: '',
    access_control_id: 2,
    lang: lang
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.USER}${id}${lang ? `?lang=${lang}` : ''}`)
        .then((res) => setUser({
          ...res.data,
          ...(lang ? { lang: lang } : {})
        }))
        .finally(() => setLoading(false))
        .catch((err) => {
          ShowErrorToast(err, "User", ErrorOnAction.Fetch)
          setLoading(false);
        });
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: UserSchema,
    onSubmit: (values) => {
      setLoading(true);
      const apiUrl = id ? `${API.USER}${id}` : API.USER;
      const successMessage = id ? 'User updated successfully!' : 'User created successfully!';
      const errorMessage = id ? 'Error updating user!' : 'Error creating user!';

      axios
        .request({
          method: id ? 'put' : 'post',
          url: apiUrl,
          data: values
        })
        .then(() => {
          toast.success(successMessage);
          navigate('/users');
        })
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error(errorMessage);
          setLoading(false);
        });
    }
  });

  const handleSubmit = formik.handleSubmit;

  return { id, user, loading, formik, handleSubmit };
};

export default useUserUpdate;
