import axios from 'axios';
import { useFormik } from 'formik'; // Import Formik
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API } from '../../API';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { IEmailSetting } from './Model';

const useEmailSettingUpdate = () => {
  const id = 1;
  const [emailSetting, setEmailSetting] = useState<IEmailSetting>({
    id: undefined,
    max_email_per_send: 0,
    every_minutes: 0,
    contact_us_email: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
    .get(`${API.EMAIL_SETTING}`)
    .then((res) => setEmailSetting(res.data))
    .finally(() => setLoading(false))
    .catch((err) => {
        ShowErrorToast(err, 'Email Setting', ErrorOnAction.Fetch);
        setLoading(false);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: emailSetting,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .put(`${API.EMAIL_SETTING}${id}`, values)
        .then(() => {
          toast.success('Email setting updated successfully!');
        })
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error('Error updating email setting!');
          setLoading(false);
        });
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { id, emailSetting, loading, formik, handleSubmit };
};

export default useEmailSettingUpdate;
