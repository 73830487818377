import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { ISubscribeType } from './Model';

const SubscribeTypeSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const useSubscribeTypeUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscribeType, setSubscribeType] = useState<ISubscribeType>({
    id: undefined,
    name: '',
    lang: lang,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.SUBSCRIBE_TYPE}${id}${lang ? `?lang=${lang}` : ''}`)
        .then((res) => setSubscribeType({
          ...res.data,
          ...(lang ? { lang: lang } : {})
        }))
        .finally(() => setLoading(false))
        .catch((err) => {
          ShowErrorToast(err, 'Subscribe Type', ErrorOnAction.Fetch);
          setLoading(false);
        });
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: subscribeType,
    validationSchema: SubscribeTypeSchema,
    onSubmit: (values) => {
      setLoading(true);
      const apiUrl = id ? `${API.SUBSCRIBE_TYPE}${id}` : API.SUBSCRIBE_TYPE;
      const successMessage = id ? 'Subscribe Type updated successfully!' : 'Subscribe Type created successfully!';
      const errorMessage = id ? 'Error updating Subscribe Type!' : 'Error creating Subscribe Type!';

      axios
        .request({
          method: id ? 'put' : 'post',
          url: apiUrl,
          data: values
        })
        .then(() => {
          toast.success(successMessage);
          navigate('/subscribe-types');
        })
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error(errorMessage);
          setLoading(false);
        });
    }
  });

  const handleSubmit = formik.handleSubmit;

  return { id, subscribeType, loading, formik, handleSubmit };
};

export default useSubscribeTypeUpdate;
