import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import { ITranslationProps } from "../Language/ITranslationProps";
import { useMinistryCategoryUpdate } from "./useMinistryCategoryUpdate";

const MinistryCategoryForm = (props: ITranslationProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useMinistryCategoryUpdate(lang);

    return (
        <GenericForm title={lang ? langName : id ? 'Edit MinistryCategory' : 'Add MinistryCategory'} onSubmit={handleSubmit} loading={loading}>
            <>
                <FormGroup>
                    <Label>Name</Label>
                    <Input
                        type="text"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name || ''}
                        invalid={formik.touched.name && !!formik.errors.name}
                        disabled={loading}
                    />
                    {formik.touched.name && formik.errors.name && <FormFeedback>{formik.errors.name}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>URL</Label>
                    <Input
                        type="text"
                        name="url"
                        onChange={formik.handleChange}
                        value={formik.values.url || ''}
                        invalid={formik.touched.url && !!formik.errors.url}
                        disabled={loading}
                    />
                    {formik.touched.url && formik.errors.url && <FormFeedback>{formik.errors.url}</FormFeedback>}
                </FormGroup>
            </>
        </GenericForm>
    )
}

export default MinistryCategoryForm;