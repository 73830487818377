import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { ILeader, LeaderType, PageType } from './Model';

const LeaderSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  long_description: Yup.string().required('Required'),
  image_url: Yup.string(),
  image: Yup.mixed(),
  is_update_image: Yup.boolean(),
});

export const useLeaderUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [leader, setLeader] = useState<ILeader>({
    name: '',
    title: '',
    long_description: '',
    image_url: '',
    image: undefined,
    is_update_image: false,
    page: PageType.HOME,
    leader_type: LeaderType.Leader1,
    lang: lang
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.LEADER}${id}${lang ? `?lang=${lang}` : ''}`)
        .then(res => setLeader({
          ...res.data,
          ...(lang ? { lang: lang } : {})
        }))
        .catch(err => toast.error('Error fetching leader!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: leader,
    validationSchema: LeaderSchema,
    onSubmit: (values, { setSubmitting }) => {
      let formData = new FormData();
      if (id) {
        formData.append('_method', 'PUT');
      }
      Object.keys(values).forEach(key => {
        formData.append(key, (values as any)[key]);
      });

      setLoading(true);
      if (id) {
        axios
          .post(`${API.LEADER}${id}`, formData)
          .then(res => {
            toast.success('Leader updated successfully!');
          })
          .catch(err => ShowErrorToast(err, "Leader", ErrorOnAction.Update))
          .finally(() => setLoading(false));
      } else {
        axios
          .post(API.LEADER, formData)
          .then(res => {
            toast.success('Leader created successfully!');
            navigate('/leaders/form/' + res.data.id, { replace: true });
          })
          .catch(err => ShowErrorToast(err, "Leader", ErrorOnAction.Create))
          .finally(() => setLoading(false));
      }

      setSubmitting(false);
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { id, leader, loading, formik, handleSubmit };
};
