import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import GenericForm from '../../Component/GenericForm';
import { useLanguageUpdate } from './useLanguageUpdate';

const LanguageUpdate: React.FC = () => {
  const { id, loading, formik, handleSubmit } = useLanguageUpdate();

  return (
    <GenericForm title={id ? 'Edit Language' : 'Add Language'} onSubmit={handleSubmit} loading={loading}>
      <>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name || ''}
            invalid={formik.touched.name && !!formik.errors.name}
            disabled={loading}
          />
          {formik.touched.name && formik.errors.name && <FormFeedback>{formik.errors.name}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label>Code</Label>
          <Input
            type="text"
            name="code"
            onChange={formik.handleChange}
            value={formik.values.code || ''}
            invalid={formik.touched.code && !!formik.errors.code}
            disabled={loading}
          />
          {formik.touched.code && formik.errors.code && <FormFeedback>{formik.errors.code}</FormFeedback>}
        </FormGroup>
      </>
    </GenericForm>
  );
};

export default LanguageUpdate;
