import { FaTimes } from "react-icons/fa";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { baseImagePath } from "../../API";
import GenericForm from "../../Component/GenericForm";
import { ITranslationProps } from "../Language/ITranslationProps";
import { LeaderType, PageType } from "./Model";
import { useLeaderUpdate } from "./useLeaderUpdate";

const LeaderForm = (props: ITranslationProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useLeaderUpdate(lang);

    return (
        <GenericForm title={lang ? langName : id ? 'Edit Leader' : 'Add Leader'} onSubmit={handleSubmit} loading={loading}
            id={id} multipleLang={true}
        >
            <>
                {!lang &&
                <>
                <FormGroup>
                    <Label>Page</Label>
                    <Input
                        type="select"
                        name="page"
                        onChange={formik.handleChange}
                        value={formik.values.page}
                        invalid={formik.touched.page && !!formik.errors.page}
                        disabled={loading}
                    >
                        <option value={PageType.HOME}>Home</option>
                        <option value={PageType.GET_TO_KNOW_US}>Get to know us</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Leader Type</Label>
                    <Input
                        type="select"
                        name="leader_type"
                        onChange={formik.handleChange}
                        value={formik.values.leader_type}
                        invalid={formik.touched.leader_type && !!formik.errors.leader_type}
                        disabled={loading}
                    >
                        <option value={LeaderType.Leader1}>Leader 1</option>
                        <option value={LeaderType.Leader2}>Leader 2</option>
                    </Input>
                </FormGroup>
                </>
                }
                <FormGroup>
                    <Label>Name</Label>
                    <Input
                        type="text"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name || ''}
                        invalid={formik.touched.name && !!formik.errors.name}
                        disabled={loading}
                    />
                    {formik.touched.name && formik.errors.name && <FormFeedback>{formik.errors.name}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Title</Label>
                    <Input
                        type="text"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title || ''}
                        invalid={formik.touched.title && !!formik.errors.title}
                        disabled={loading}
                    />
                    {formik.touched.title && formik.errors.title && <FormFeedback>{formik.errors.title}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Input
                        type="text"
                        name="long_description"
                        onChange={formik.handleChange}
                        value={formik.values.long_description || ''}
                        invalid={formik.touched.long_description && !!formik.errors.long_description}
                        disabled={loading}
                    />
                    {formik.touched.long_description && formik.errors.long_description && <FormFeedback>{formik.errors.long_description}</FormFeedback>}
                </FormGroup>
                {!lang &&
                    <FormGroup>
                        <Label>Image</Label>
                        {formik.values.image_url && (
                            <div className="formImage">
                                <img src={`${baseImagePath}${formik.values.image_url}`} alt="Leader Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);
                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        {formik.values.image && (
                            <div className="formImage">
                                <img
                                    src={URL.createObjectURL(formik.values.image)}
                                    alt="Leader Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);
                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        <Input
                            type="file"
                            name="image"
                            onChange={event => {
                                formik.setFieldValue('image_url', '');
                                formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : undefined);
                                if (id) formik.setFieldValue('is_update_image', true);
                            }}
                            invalid={formik.touched.image && !!formik.errors.image}
                            disabled={loading}
                        />
                        {formik.touched.image && formik.errors.image && <FormFeedback>{formik.errors.image}</FormFeedback>}
                    </FormGroup>
                }
            </>
        </GenericForm>
    );
};

export default LeaderForm;
