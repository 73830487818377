import { FaTimes } from 'react-icons/fa';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { baseImagePath } from '../../API';
import GenericForm from '../../Component/GenericForm';
import { NoWheel } from '../../Helper/NoWheel';
import { ITranslationProps } from "../Language/ITranslationProps";
import { useServiceUpdate } from './useServiceUpdate';

const ServiceForm = (props: ITranslationProps) => {
    const { lang, langName } = props;
    const { id, loading, formik, handleSubmit } = useServiceUpdate(lang);

    return (
        <GenericForm title={lang ? langName : id ? 'Edit Service' : 'Add Service'} onSubmit={handleSubmit} loading={loading}
            id={id} multipleLang={true}
        >
            <>
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        type="text"
                        name="type"
                        onChange={formik.handleChange}
                        value={formik.values.type || ''}
                        invalid={formik.touched.type && !!formik.errors.type}
                        disabled={loading}
                    />
                    {formik.touched.type && formik.errors.type && <FormFeedback>{formik.errors.type}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Title</Label>
                    <Input
                        type="text"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title || ''}
                        invalid={formik.touched.title && !!formik.errors.title}
                        disabled={loading}
                    />
                    {formik.touched.title && formik.errors.title && <FormFeedback>{formik.errors.title}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Input
                        type="text"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description || ''}
                        invalid={formik.touched.description && !!formik.errors.description}
                        disabled={loading}
                    />
                    {formik.touched.description && formik.errors.description && <FormFeedback>{formik.errors.description}</FormFeedback>}
                </FormGroup>
                {!lang &&
                    <>
                    <FormGroup>
                        <Label>Image</Label>
                        {formik.values.image_url && (
                            <div className="formImage">
                                <img src={`${baseImagePath}${formik.values.image_url}`} alt="Service Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);

                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        {formik.values.image && (
                            <div className="formImage">
                                <img
                                    src={URL.createObjectURL(formik.values.image)}
                                    alt="Service Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);

                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        <Input
                            type="file"
                            name="image"
                            onChange={event => {
                                formik.setFieldValue('image_url', '');
                                formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : undefined);
                                if (id) formik.setFieldValue('is_update_image', true);
                            }}
                            invalid={formik.touched.image && !!formik.errors.image}
                            disabled={loading}
                        />
                        {formik.touched.image && formik.errors.image && <FormFeedback>{formik.errors.image}</FormFeedback>}
                    </FormGroup>
                
                <FormGroup>
                    <Label>Link Text</Label>
                    <Input
                        type="text"
                        name="link_text"
                        onChange={formik.handleChange}
                        value={formik.values.link_text || ''}
                        invalid={formik.touched.link_text && !!formik.errors.link_text}
                        disabled={loading}
                    />
                    {formik.touched.link_text && formik.errors.link_text && <FormFeedback>{formik.errors.link_text}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Link URL</Label>
                    <Input
                        type="text"
                        name="link_url"
                        onChange={formik.handleChange}
                        value={formik.values.link_url || ''}
                        invalid={formik.touched.link_url && !!formik.errors.link_url}
                        disabled={loading}
                    />
                    {formik.touched.link_url && formik.errors.link_url && <FormFeedback>{formik.errors.link_url}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Day</Label>
                    <Input
                        type="number" onWheel={NoWheel}
                        name="day"
                        onChange={formik.handleChange}
                        value={formik.values.day || ''}
                        invalid={formik.touched.day && !!formik.errors.day}
                        disabled={loading}
                    />
                    {formik.touched.day && formik.errors.day && <FormFeedback>{formik.errors.day}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Month</Label>
                    <Input
                        type="number" onWheel={NoWheel}
                        name="month"
                        onChange={formik.handleChange}
                        value={formik.values.month || ''}
                        invalid={formik.touched.month && !!formik.errors.month}
                        disabled={loading}
                    />
                    {formik.touched.month && formik.errors.month && <FormFeedback>{formik.errors.month}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Year</Label>
                    <Input
                        type="number" onWheel={NoWheel}
                        name="year"
                        onChange={formik.handleChange}
                        value={formik.values.year || ''}
                        invalid={formik.touched.year && !!formik.errors.year}
                        disabled={loading}
                    />
                    {formik.touched.year && formik.errors.year && <FormFeedback>{formik.errors.year}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Day of Week</Label>
                    <Input
                        type="text"
                        name="day_of_week"
                        onChange={formik.handleChange}
                        value={formik.values.day_of_week || ''}
                        invalid={formik.touched.day_of_week && !!formik.errors.day_of_week}
                        disabled={loading}
                    />
                    {formik.touched.day_of_week && formik.errors.day_of_week && <FormFeedback>{formik.errors.day_of_week}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Church Time</Label>
                    {formik.values.church_time.map((time: number, index: number) => (
                        <div key={index} className="d-flex align-items-center">
                            <Input
                                type="number" onWheel={NoWheel}
                                value={time}
                                onChange={event => {
                                    const newTimes = [...formik.values.church_time];
                                    newTimes[index] = Number(event.target.value);
                                    formik.setFieldValue('church_time', newTimes);
                                }}
                                invalid={formik.touched.church_time && !!formik.errors.church_time}
                                disabled={loading}
                            />
                            <Button
                                color="danger"
                                onClick={() => {
                                    const newTimes = [...formik.values.church_time];
                                    newTimes.splice(index, 1);
                                    formik.setFieldValue('church_time', newTimes);
                                }}
                                className="ml-2"
                                disabled={loading}
                            >
                                Remove
                            </Button>
                            {formik.touched.church_time && formik.errors.church_time && <FormFeedback>{formik.errors.church_time}</FormFeedback>}
                        </div>
                    ))}
                </FormGroup>
                <FormGroup>
                    <Button
                        color="primary"
                        onClick={() => {
                            formik.setFieldValue('church_time', [...formik.values.church_time, 0]);
                        }}
                        disabled={loading}
                    >
                        Add Time
                    </Button>
                </FormGroup>
                <FormGroup>
                    <Label>Join Link</Label>
                    <Row>
                        <Col xs={1} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Input
                                type="checkbox"
                                name="join_link_enable"
                                onChange={(e) => {
                                    formik.setFieldValue('join_link_enable', e.target.checked ? 1 : 0);
                                }}
                                invalid={formik.touched.join_link_enable && !!formik.errors.join_link_enable}
                                disabled={loading}
                                checked={formik.values.join_link_enable ? true : false }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="join_link"
                                onChange={formik.handleChange}
                                value={formik.values.join_link || ''}
                                invalid={formik.touched.join_link && !!formik.errors.join_link}
                                disabled={loading}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                </>}
            </>
        </GenericForm>
    );
};

export default ServiceForm;
