import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { API } from "../../API";
import { useLanguageList } from "../Language/useLanguageList";
import { ISubscribeType } from "../SubscribeType/Model";
import EventForm from "./EventForm";

const EventUpdate: React.FC = () => {
    const { loading, langList } = useLanguageList()
    const [subscribeTypes, setSubscribeTypes] = useState<ISubscribeType[]>([])
    const [loadingST, setLoadingST] = useState<boolean>(false);

    useEffect(() => {
        setLoadingST(true);
        axios
            .get(API.SUBSCRIBE_TYPE)
            .then(res => setSubscribeTypes(res.data))
            .finally(() => setLoadingST(false))
            .catch(err => toast.error('Error fetching subscribe type!'));
    }, []);

    if (loadingST) return (<>
        <Spinner color="light" /> Loading Subscribe Type
    </>
    );

    return (
        <>
            <EventForm subscribeTypes={subscribeTypes} />
            {loading ? (<div>Loading language list <Spinner size="sm" color="dark" /></div>) : ''}
            {langList.map((lang) =>
                <EventForm lang={lang.code} langName={lang.name} key={lang.code} subscribeTypes={subscribeTypes} />
            )}
        </>
    );
};

export default EventUpdate;
