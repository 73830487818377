import { SubscribeTypeForm } from "./SubscribeTypeForm";

const SubscribeTypeUpdate = () => {
  return (
    <>
      <SubscribeTypeForm />
    </>
  );
}

export default SubscribeTypeUpdate;
