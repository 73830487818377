import { FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import { useChangePasswordUpdate } from "./useChangePasswordUpdate";

export const ChangePassword = () => {
    const { loading, formik, handleSubmit } = useChangePasswordUpdate();

    return <>
        <GenericForm title={"Change Password"} onSubmit={handleSubmit} loading={loading}>
            <>
            <FormGroup>
                <Label htmlFor="old_password">Old Password</Label>
                <Input
                    id="old_password"
                    name="old_password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.old_password}
                    disabled={loading}
                />
                {formik.errors.old_password && <div className="text-danger">{formik.errors.old_password}</div>}
            </FormGroup>
            <FormGroup>
                <Label htmlFor="new_password">New Password</Label>
                <Input
                    id="new_password"
                    name="new_password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.new_password}
                    disabled={loading}
                />
                {formik.errors.new_password && <div className="text-danger">{formik.errors.new_password}</div>}
            </FormGroup>
            <FormGroup>
                <Label htmlFor="retry_password">Retype Password</Label>
                <Input
                    id="retry_password"
                    name="retry_password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.retry_password}
                    disabled={loading}
                />
                {formik.errors.retry_password && <div className="text-danger">{formik.errors.retry_password}</div>}
            </FormGroup>
            </>
        </GenericForm>
    </>
}