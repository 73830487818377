import { Spinner } from "reactstrap";
import { useLanguageList } from "../Language/useLanguageList";
import { SlideForm } from "./SlideForm";

const SlideUpdate = () => {
  const { loading, langList } = useLanguageList()

  return (
    <>
      <SlideForm />
      {loading ? (<div>Loading language list <Spinner size="sm" color="dark" /></div>) : ''}
      { langList.map((lang) => 
        <SlideForm lang={lang.code} langName={lang.name} key={lang.code} />
      ) }
    </>
  );
}

export default SlideUpdate;
