import axios from 'axios';
import { useFormik } from 'formik'; // Import Formik
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API } from '../../API';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { ILink } from './Model';

const useLinkUpdate = () => {
  const [links, setLinks] = useState<ILink>({
    instagram_url: '',
    facebook_url: '',
    tiktok_url: '',
    youtube_url: ''
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
    .get(`${API.LINKS}`)
    .then((res) => setLinks(res.data))
    .finally(() => setLoading(false))
    .catch((err) => {
        ShowErrorToast(err, 'Links Setting', ErrorOnAction.Fetch);
        setLoading(false);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: links,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .put(`${API.LINKS}`, values)
        .then(() => {
          toast.success('Links setting updated successfully!');
        })
        .finally(() => setLoading(false))
        .catch(() => {
          toast.error('Error updating Links setting!');
          setLoading(false);
        });
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { links, loading, formik, handleSubmit };
};

export default useLinkUpdate;
