import { FaTimes } from 'react-icons/fa';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { baseImagePath } from '../../API';
import GenericForm from '../../Component/GenericForm';
import { ITranslationProps } from "../Language/ITranslationProps";
import { ISubscribeType } from '../SubscribeType/Model';
import { useEventUpdate } from './useEventUpdate';

interface EventFormProps extends ITranslationProps {
    subscribeTypes: ISubscribeType[]
}

const EventForm = (props: EventFormProps) => {
    const { lang, langName, subscribeTypes } = props;
    const { id, loading, formik, handleSubmit } = useEventUpdate(lang);

    return (
        <GenericForm
            title={lang ? langName : id ? 'Edit Event' : 'Add Event'}
            onSubmit={handleSubmit}
            loading={loading}
            id={id} multipleLang={true}
        >
            <>
                {!lang &&
                <FormGroup>
                    <Label>Subscribe Type</Label>
                    <Input
                        type="select"
                        name="subscribe_type_id"
                        onChange={formik.handleChange}
                        value={formik.values.subscribe_type_id}
                        invalid={formik.touched.subscribe_type_id && !!formik.errors.subscribe_type_id}
                        disabled={loading}
                    >
                        <option>Select Subscribe Type</option>
                        {subscribeTypes.map((subscribe_type) => (
                            <option key={subscribe_type.id} value={subscribe_type.id}>{subscribe_type.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                }
                <FormGroup>
                    <Label>Title</Label>
                    <Input
                        type="text"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title || ''}
                        invalid={formik.touched.title && !!formik.errors.title}
                        disabled={loading}
                    />
                    {formik.touched.title && formik.errors.title && (
                        <FormFeedback>{formik.errors.title}</FormFeedback>
                    )}
                </FormGroup>
                {!lang &&
                    <>
                    <FormGroup>
                        <Label>Date</Label>
                        <Input
                            type="date"
                            name="date_date"
                            onChange={(e) => {
                                formik.handleChange(e)
                                formik.handleChange({
                                    target: {
                                        name: 'date',
                                        value: new Date(e.target.value).toISOString()
                                    }
                                })
                            }}
                            value={formik.values.date_date?.toString() ?? ""}
                            invalid={formik.touched.date_date && !!formik.errors.date_date}
                            disabled={loading}
                        />
                        {formik.touched.date && formik.errors.date && (
                            <FormFeedback>{formik.errors.date}</FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                    <Label>Info</Label>
                    <Input
                        type="text"
                        name="info"
                        onChange={formik.handleChange}
                        value={formik.values.info || ''}
                        invalid={formik.touched.info && !!formik.errors.info}
                        disabled={loading}
                    />
                </FormGroup>
                    <FormGroup>
                        <Label>Image</Label>
                        {formik.values.image_url && (
                            <div className="formImage">
                                <img src={`${baseImagePath}${formik.values.image_url}`} alt="Service Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);

                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        {formik.values.image && (
                            <div className="formImage">
                                <img
                                    src={URL.createObjectURL(formik.values.image)}
                                    alt="Service Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);

                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        <Input
                            type="file"
                            name="image"
                            onChange={event => {
                                formik.setFieldValue('image_url', '');
                                formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : undefined);
                                if (id) formik.setFieldValue('is_update_image', true);
                            }}
                            invalid={formik.touched.image && !!formik.errors.image}
                            disabled={loading}
                        />
                        {formik.touched.image && formik.errors.image && <FormFeedback>{formik.errors.image}</FormFeedback>}
                    </FormGroup>
                    </>
                }
                <FormGroup>
                    <Label>Tagline</Label>
                    <Input
                        type="text"
                        name="tagline"
                        onChange={formik.handleChange}
                        value={formik.values.tagline || ''}
                        invalid={formik.touched.tagline && !!formik.errors.tagline}
                        disabled={loading}
                    />
                    {formik.touched.tagline && formik.errors.tagline && (
                        <FormFeedback>{formik.errors.tagline}</FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Input
                        type="text"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description || ''}
                        invalid={formik.touched.description && !!formik.errors.description}
                        disabled={loading}
                    />
                    {formik.touched.description && formik.errors.description && (
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                    )}
                </FormGroup>
                {!lang &&
                <FormGroup>
                    <Label>Join Link</Label>
                    <Row>
                        <Col xs={1} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Input
                                type="checkbox"
                                name="join_link_enable"
                                onChange={(e) => {
                                    formik.setFieldValue('join_link_enable', e.target.checked ? 1 : 0);
                                }}
                                invalid={formik.touched.join_link_enable && !!formik.errors.join_link_enable}
                                disabled={loading}
                                checked={formik.values.join_link_enable ? true : false }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="join_link"
                                onChange={formik.handleChange}
                                value={formik.values.join_link || ''}
                                invalid={formik.touched.join_link && !!formik.errors.join_link}
                                disabled={loading}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                }
            </>
        </GenericForm>
    );
};

export default EventForm;
