import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import { API } from '../../API';
import ConfirmationModal from '../../Component/ConfirmationModal';
import { useModal } from '../../Hook/useModal';

export const Leader = () => {
  const [leaders, setLeaders] = useState<any[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { modalOpen, data, openModal, closeModal } = useModal();

  useEffect(() => {
    axios
      .get(API.LEADER)
      .then(res => setLeaders(res.data))
      .catch(err => toast.error('Error fetching leaders!'));
  }, []);

  const confirmDeleteItem = () => {
    if (data !== null && data.id !== undefined) {
      setLoading(true);
      axios
        .delete(`${API.LEADER}${data.id}`)
        .then(() => {
          toast.success('Leader deleted successfully!');
          setLeaders(leaders.filter(leader => leader.id !== data.id));
        })
        .finally(() => setLoading(false))
        .catch(err => {
          toast.error('Error deleting leader!');
          setLoading(false);
        });
    }
    closeModal();
  };

  const cancelDeleteItem = () => {
    closeModal();
  };

  const goToForm = (id?: number) => {
    navigate(`/leaders/form${id ? `/${id}` : ''}`);
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>Leaders</h1>
        </Col>
        <Col className="align-right">
          <Button color="primary" onClick={() => goToForm()}>
            Add New
          </Button>
        </Col>
      </Row>

      <Card className="mt-4">
        <CardHeader>Leader List</CardHeader>
        <CardBody>
          <Table striped responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Title</th>
                <th className="align-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {leaders.map((leader, index) => (
                <tr key={leader.id}>
                  <td>{index + 1}</td>
                  <td>{leader.name}</td>
                  <td>{leader.title}</td>
                  <td className="align-right">
                    <Button color="warning" onClick={() => goToForm(leader.id)} disabled={loading}>
                      Edit
                    </Button>{" "}
                    <Button
                      color="danger"
                      onClick={() => openModal(leader)}
                      disabled={loading}
                    >
                      Delete
                      {loading ? <Spinner size="sm" color="light" /> : null}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <ConfirmationModal
        isOpen={modalOpen}
        toggle={cancelDeleteItem}
        onConfirm={confirmDeleteItem}
        title="Confirm Delete"
        message="Are you sure you want to delete this leader?"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </Container>
  );
};
