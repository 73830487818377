import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { IVisitUs } from './Model';

const VisitUsSchema = Yup.object().shape({
  place: Yup.object().shape({
    name: Yup.string().required(),
    location: Yup.string().required()
  })
});

export const useVisitUsUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [visitUs, setVisitUs] = useState<IVisitUs>({
    place: {
      name: '',
      location: '',
      map_url: ''
    },
    social_media: {
      email: {
        label: '',
        url: '',
      },
      fb: {
        label: '',
        url: '',
      },
      ig: {
        label: '',
        url: '',
      },
      tiktok: {
        label: '',
        url: '',
      },
      youtube: {
        label: '',
        url: '',
      },
    },
    contact: [],
    active: true,
    lang: lang
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.VISIT_US}${id}${lang ? `?lang=${lang}` : ''}`)
        .then((res) => {
          res.data.contact = res.data.contact ?? []
          res.data.active = res.data.active === "1" ? true : false
          setVisitUs(res.data)
        })
        .catch((err) => toast.error('Error fetching Visit Us data!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: visitUs,
    validationSchema: VisitUsSchema,
    onSubmit: (values, { setSubmitting }) => {
      let formData = new FormData();
      if (id) {
        formData.append('_method', 'PUT');
      }

      // Place
      formData.append('place[name]', values.place.name);
      if (values.place.image) {
        formData.append('place[image]', values.place.image);
      }
      if (values.place.is_update_image) {
        formData.append('place[is_update_image]', values.place.is_update_image as any)
      }
      formData.append('place[location]', values.place.location);
      formData.append('place[map_url]', values.place.map_url ?? "");

      // Social Media
      formData.append('social_media[email][label]', values.social_media.email.label ?? "");
      formData.append('social_media[email][url]', values.social_media.email.url ?? "");
      formData.append('social_media[fb][label]', values.social_media.fb.label ?? "");
      formData.append('social_media[fb][url]', values.social_media.fb.url ?? "");
      formData.append('social_media[ig][label]', values.social_media.ig.label ?? "");
      formData.append('social_media[ig][url]', values.social_media.ig.url ?? "");
      formData.append('social_media[tiktok][label]', values.social_media.tiktok.label ?? "");
      formData.append('social_media[tiktok][url]', values.social_media.tiktok.url ?? "");
      formData.append('social_media[youtube][label]', values.social_media.youtube.label ?? "");
      formData.append('social_media[youtube][url]', values.social_media.youtube.url ?? "");

      // Contact
      values.contact.forEach((contact, index) => {
        formData.append(`contact[${index}][type]`, contact.type ?? "");
        formData.append(`contact[${index}][label]`, contact.label ?? "");
        formData.append(`contact[${index}][url]`, contact.url ?? "");
      });

      // Active
      formData.append('active', values.active ? '1' : '0');

      // Lang
      if(lang) formData.append('lang', lang);

      setLoading(true);
      if (id) {
        axios
          .post(`${API.VISIT_US}${id}`, formData)
          .then((res) => {
            toast.success('Visit Us data updated successfully!');
            navigate('/visit-us');
          })
          .catch((err) => toast.error('Error updating Visit Us data!'))
          .finally(() => setLoading(false));
      } else {
        axios
          .post(API.VISIT_US, formData)
          .then((res) => {
            toast.success('Visit Us data created successfully!');
            navigate('/visit-us');
          })
          .catch((err) => toast.error('Error creating Visit Us data!'))
          .finally(() => setLoading(false));
      }

      setSubmitting(false);
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { id, visitUs, loading, formik, handleSubmit };
};
