import { FaTimes } from "react-icons/fa";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { baseImagePath } from "../../API";
import GenericForm from "../../Component/GenericForm";
import { ITranslationProps } from "../Language/ITranslationProps";
import { IMinistryCategory } from "../MinistryCategory/Model";
import { ISubscribeType } from "../SubscribeType/Model";
import { useMinistryUpdate } from "./useMinistryUpdate";

interface MinistryFormProps extends ITranslationProps {
    ministryCategories: IMinistryCategory[]
    subscribeTypes: ISubscribeType[]
}

const MinistryForm = (props: MinistryFormProps) => {
    const { lang, langName, ministryCategories, subscribeTypes } = props;
    const { id, loading, formik, handleSubmit } = useMinistryUpdate(lang);

    return (
        <GenericForm title={lang ? langName : id ? 'Edit Ministry' : 'Add Ministry'} onSubmit={handleSubmit} loading={loading}
            id={id} multipleLang={true}
        >
            <>
                {!lang &&
                <>
                <FormGroup>
                    <Label>Ministry Category</Label>
                    <Input
                        type="select"
                        name="ministry_category_id"
                        onChange={formik.handleChange}
                        value={formik.values.ministry_category_id}
                        invalid={formik.touched.ministry_category_id && !!formik.errors.ministry_category_id}
                        disabled={loading}
                    >
                        <option>Select Ministry Category</option>
                        {ministryCategories.map((ministryCategory) => (
                            <option key={ministryCategory.id} value={ministryCategory.id}>{ministryCategory.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Subscribe Type</Label>
                    <Input
                        type="select"
                        name="subscribe_type_id"
                        onChange={formik.handleChange}
                        value={formik.values.subscribe_type_id}
                        invalid={formik.touched.subscribe_type_id && !!formik.errors.subscribe_type_id}
                        disabled={loading}
                    >
                        <option>Select Subscribe Type</option>
                        {subscribeTypes.map((subscribe_type) => (
                            <option key={subscribe_type.id} value={subscribe_type.id}>{subscribe_type.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                </>
                }
                <FormGroup>
                    <Label>Menu</Label>
                    <Input
                        type="text"
                        name="menu"
                        onChange={formik.handleChange}
                        value={formik.values.menu || ''}
                        invalid={formik.touched.menu && !!formik.errors.menu}
                        disabled={loading}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Link</Label>
                    <Input
                        type="text"
                        name="link"
                        onChange={formik.handleChange}
                        value={formik.values.link || ''}
                        invalid={formik.touched.link && !!formik.errors.link}
                        disabled={loading}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Title</Label>
                    <Input
                        type="text"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title || ''}
                        invalid={formik.touched.title && !!formik.errors.title}
                        disabled={loading}
                    />
                </FormGroup>
                {!lang &&
                    <FormGroup>
                        <Label>Image</Label>
                        {formik.values.image_url && (
                            <div className="formImage">
                                <img src={`${baseImagePath}${formik.values.image_url}`} alt="Leader Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);
                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        {formik.values.image && (
                            <div className="formImage">
                                <img
                                    src={URL.createObjectURL(formik.values.image)}
                                    alt="Ministry Image" />
                                <Button
                                    className="remove"
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        formik.setFieldValue('image_url', '');
                                        formik.setFieldValue('image', undefined);
                                        if (id) formik.setFieldValue('is_update_image', true);
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </div>
                        )}
                        <Input
                            type="file"
                            name="image"
                            onChange={event => {
                                formik.setFieldValue('image_url', '');
                                formik.setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : undefined);
                                if (id) formik.setFieldValue('is_update_image', true);
                            }}
                            invalid={formik.touched.image && !!formik.errors.image}
                            disabled={loading}
                        />
                        {formik.touched.image && formik.errors.image && <FormFeedback>{formik.errors.image}</FormFeedback>}
                    </FormGroup>
                }
                <FormGroup>
                    <Label>Content</Label>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Title</Label>
                                <Input
                                    type="text"
                                    name="content_title"
                                    onChange={formik.handleChange}
                                    value={formik.values.content_title || ''}
                                    invalid={formik.touched.content_title && !!formik.errors.content_title}
                                    disabled={loading}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Tagline</Label>
                                <Input
                                    type="text"
                                    name="content_tagline"
                                    onChange={formik.handleChange}
                                    value={formik.values.content_tagline || ''}
                                    invalid={formik.touched.content_tagline && !!formik.errors.content_tagline}
                                    disabled={loading}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Short Description</Label>
                        <Input
                            type="textarea"
                            name="content_short_description"
                            onChange={formik.handleChange}
                            value={formik.values.content_short_description || ''}
                            invalid={formik.touched.content_short_description && !!formik.errors.content_short_description}
                            disabled={loading}
                        />
                    </FormGroup>
                </FormGroup>
                {!lang &&
                <>
                <FormGroup>
                    <Label>Info</Label>
                    <Input
                        type="textarea"
                        name="info"
                        onChange={formik.handleChange}
                        value={formik.values.info || ''}
                        invalid={formik.touched.info && !!formik.errors.info}
                        disabled={loading}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Join Link</Label>
                    <Row>
                        <Col xs={1} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Input
                                type="checkbox"
                                name="join_link_enable"
                                onChange={(e) => {
                                    formik.setFieldValue('join_link_enable', e.target.checked ? 1 : 0);
                                }}
                                invalid={formik.touched.join_link_enable && !!formik.errors.join_link_enable}
                                disabled={loading}
                                checked={formik.values.join_link_enable ? true : false }
                            />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="join_link"
                                onChange={formik.handleChange}
                                value={formik.values.join_link || ''}
                                invalid={formik.touched.join_link && !!formik.errors.join_link}
                                disabled={loading}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                </>
                }
            </>
        </GenericForm>
    )
}

export default MinistryForm;