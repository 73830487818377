import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import { IEvent } from './Model';

const EventSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  date: Yup.string().required('Date is required'),
});

export const useEventUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState<IEvent>({
    id: undefined,
    title: '',
    date: '',
    image: undefined,
    image_url: '',
    is_update_image: false,
    tagline: '',
    description: '',
    subscribe_type_id: undefined,
    join_link: '',
    join_link_enable: 0,
    info: ''
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.EVENT}${id}${lang ? `?lang=${lang}` : ''}`)
        .then((res) =>
          setEvent({
            ...res.data,
            ...(res.data.date ? { date_date: new Date(res.data.date).toISOString().split('T')[0] } : {}),
            ...(lang ? { lang: lang } : {}),
          })
        )
        .catch((err) => toast.error('Error fetching event!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: event,
    validationSchema: EventSchema,
    onSubmit: (values, { setSubmitting }) => {
      let formData = new FormData();
      if (id) {
        formData.append('_method', 'PUT');
      }
      Object.keys(values).forEach((key) => {
        const d = values as any;
        if (d[key] instanceof Array) {
          d[key].forEach((item: any) => {
            formData.append(key + '[]', item);
          });
          return;
        }
        formData.append(key, d[key] ?? "");
      });

      setLoading(true);
      if (id) {
        axios
          .post(`${API.EVENT}${id}`, formData)
          .then((res) => {
            toast.success('Event updated successfully!');
          })
          .catch((err) => toast.error('Error updating event!'))
          .finally(() => setLoading(false));
      } else {
        axios
          .post(API.EVENT, formData)
          .then((res) => {
            toast.success('Event created successfully!');
            navigate('/events/form/' + res.data.id, { replace: true });
          })
          .catch((err) => toast.error('Error creating event!'))
          .finally(() => setLoading(false));
      }

      setSubmitting(false);
    },
  });

  const handleSubmit = formik.handleSubmit;
  return { id, loading, formik, handleSubmit };
};
