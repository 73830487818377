import { useState } from "react";

export const useModal = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);

    const openModal = (data?: any) => {
        setData(data);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setData(null);
    };

    return { modalOpen, data, openModal, closeModal };
};