import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';
import { API } from '../../API';
import ConfirmationModal from '../../Component/ConfirmationModal';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { useModal } from '../../Hook/useModal';
import { IAccessControl } from '../AccessControl/Model';
import { IUser } from './Model'; // Import the IUser model

const User: React.FC = () => {
    const [accessControls, setAccessControls] = useState<IAccessControl[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { modalOpen, data, openModal, closeModal } = useModal();

    useEffect(() => {
        axios
            .get(API.ACCESS_CONTROL)
            .then(res => setAccessControls(res.data))
            .catch(err => ShowErrorToast(err, "Access Control", ErrorOnAction.Fetch));
    }, []);

    useEffect(() => {
        if (accessControls.length === 0) return;
        axios
            .get(API.USER)
            .then(res => setUsers(res.data))
            .catch(err => ShowErrorToast(err, "User", ErrorOnAction.Fetch));
    }, [accessControls])

    const confirmDeleteUser = () => {
        if (data !== null && data.id !== undefined) {
            setLoading(true);
            axios
                .delete(`${API.USER}${data.id}`)
                .then(() => {
                    toast.success('User deleted successfully!');
                    setUsers(users.filter(user => user.id !== data.id));
                })
                .finally(() => setLoading(false))
                .catch(err => {
                    toast.error('Error deleting user!');
                    setLoading(false);
                });
        }
        closeModal();
    };

    const cancelDeleteUser = () => {
        closeModal();
    };

    const goToForm = (id?: number) => {
        navigate(`/users/form${id ? `/${id}` : ''}`);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Users</h1>
                </Col>
                <Col className="align-right">
                    <Button color="primary" onClick={() => goToForm()}>
                        Add New
                    </Button>
                </Col>
            </Row>
            <Row className="separator" />
            <Row>
                <Col>
                    <Card>
                        <CardHeader>User List</CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Access Control</th>
                                        <th className="align-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user, index) => (
                                        <tr key={user.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{accessControls.find(accessControl => accessControl.id === user.access_control_id)?.name}</td>
                                            <td className="align-right">
                                                <Button color="warning" onClick={() => goToForm(user.id)} disabled={loading}>
                                                    Edit
                                                </Button>{" "}
                                                <Button color="danger" onClick={() => openModal(user)} disabled={loading}>
                                                    Delete
                                                    {loading ? <Spinner size="sm" color="light" /> : null}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal
                isOpen={modalOpen}
                toggle={cancelDeleteUser}
                onConfirm={confirmDeleteUser}
                title="Confirm Delete"
                message="Are you sure you want to delete this user?"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </Container>
    );
};

export default User;
