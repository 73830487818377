import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from "reactstrap";
import { API } from "../../API";
import ConfirmationModal from "../../Component/ConfirmationModal";
import { useModal } from "../../Hook/useModal";
import { IMinistryCategory } from "../MinistryCategory/Model";
import { IMinistry } from "./Model";

export const Ministry = () => {
    const [ministries, setMinistries] = useState<IMinistry[]>([])
    const [ministryCategories, setMinistryCategories] = useState<IMinistryCategory[]>([]);
    const [loadingMC, setLoadingMC] = useState<boolean>(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { modalOpen, data, openModal, closeModal } = useModal();

    useEffect(() => {
        setLoadingMC(true);
        axios
            .get(API.MINISTRY_CATEGORY)
            .then(res => setMinistryCategories(res.data))
            .finally(() => setLoadingMC(false))
            .catch(err => toast.error('Error fetching ministry categories!'));
    }, []);

    useEffect(() => {
        if(ministryCategories.length <= 0) return;

        axios
            .get(API.MINISTRY)
            .then(res => setMinistries(res.data))
            .catch(err => toast.error('Error fetching ministries!'));
    }, [ministryCategories]);

    const confirmDeleteItem = () => {
        if (data !== null && data.id !== undefined) {
            setLoading(true);
            axios
                .delete(`${API.MINISTRY}${data.id}`)
                .then(() => {
                    toast.success('Ministry deleted successfully!');
                    setMinistries(ministries.filter(ministry => ministry.id !== data.id));
                })
                .finally(() => setLoading(false))
                .catch(err => {
                    toast.error('Error deleting Ministry!');
                    setLoading(false);
                });
        }
        closeModal();
    };

    const cancelDeleteItem = () => {
        closeModal();
    };

    const goToForm = (id?: number) => {
        navigate(`/ministries/form${id ? `/${id}` : ''}`);
    };

    if(loadingMC) {
        return <Spinner color="dark" />
    }

    if(ministryCategories.length == 0) {
        return <p>Must add Ministry Category first!</p>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Ministries</h1>
                </Col>
                <Col className="align-right">
                    <Button color="primary" onClick={() => goToForm()}>
                        Add New
                    </Button>
                </Col>
            </Row>

            <Card className="mt-4">
                <CardHeader>Ministry List</CardHeader>
                <CardBody>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category</th>
                                <th>Title</th>
                                <th className="align-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ministries.map((ministry, index) => (
                                <tr key={ministry.id}>
                                    <td>{index + 1}</td>
                                    <td>{ministryCategories.find( mc => +(mc.id ?? -1) === +(ministry.ministry_category_id ?? - 1))?.name ?? "-" }</td>
                                    <td>{ministry.title}</td>
                                    <td className="align-right">
                                        <Button color="warning" onClick={() => goToForm(ministry.id)} disabled={loading}>
                                            Edit
                                        </Button>{" "}
                                        <Button
                                            color="danger"
                                            onClick={() => openModal(ministry)}
                                            disabled={loading}
                                        >
                                            Delete
                                            {loading ? <Spinner size="sm" color="light" /> : null}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <ConfirmationModal
                isOpen={modalOpen}
                toggle={cancelDeleteItem}
                onConfirm={confirmDeleteItem}
                title="Confirm Delete"
                message="Are you sure you want to delete this Ministry?"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </Container>
    );
}