import { FaTimes } from 'react-icons/fa';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import GenericForm from '../../Component/GenericForm';
import { DisplayType, LinkType, MediaCategory } from './Model';
import { useMediaUpdate } from './useMediaUpdate';
import { NoWheel } from '../../Helper/NoWheel';

const MediaForm = () => {
    const { id, loading, formik, handleSubmit } = useMediaUpdate();

    return (
        <GenericForm title={id ? 'Edit Media' : 'Add Media'} onSubmit={handleSubmit} loading={loading}>
            <>
                <FormGroup>
                    <Label>Section</Label>
                    <Input
                        type="text"
                        name="section"
                        onChange={formik.handleChange}
                        value={formik.values.section || ''}
                        invalid={formik.touched.section && !!formik.errors.section}
                        disabled={loading}
                    />
                    {formik.touched.section && formik.errors.section && <FormFeedback>{formik.errors.section}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Category</Label>
                    <Input
                        type="select"
                        name="category"
                        onChange={formik.handleChange}
                        value={formik.values.category || ''}
                        invalid={formik.touched.category && !!formik.errors.category}
                        disabled={loading}
                    >
                        {Object.values(MediaCategory).map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </Input>
                    {formik.touched.category && formik.errors.category && <FormFeedback>{formik.errors.category}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Link Type</Label>
                    <Input
                        type="select"
                        name="link_type"
                        onChange={formik.handleChange}
                        value={formik.values.link_type || ''}
                        invalid={formik.touched.link_type && !!formik.errors.link_type}
                        disabled={loading}
                    >
                        {Object.values(LinkType).map((linkType) => (
                            <option key={linkType} value={linkType}>
                                {linkType}
                            </option>
                        ))}
                    </Input>
                    {formik.touched.link_type && formik.errors.link_type && <FormFeedback>{formik.errors.link_type}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Display Type</Label>
                    <Input
                        type="select"
                        name="display_type"
                        onChange={formik.handleChange}
                        value={formik.values.display_type || ''}
                        invalid={formik.touched.display_type && !!formik.errors.display_type}
                        disabled={loading}
                    >
                        {Object.values(DisplayType).map((displayType) => (
                            <option key={displayType} value={displayType}>
                                {displayType}
                            </option>
                        ))}
                    </Input>
                    {formik.touched.display_type && formik.errors.display_type && <FormFeedback>{formik.errors.display_type}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>View More URL</Label>
                    <Input
                        type="text"
                        name="view_more_url"
                        onChange={formik.handleChange}
                        value={formik.values.view_more_url || ''}
                        invalid={formik.touched.view_more_url && !!formik.errors.view_more_url}
                        disabled={loading}
                    />
                    {formik.touched.view_more_url && formik.errors.view_more_url && <FormFeedback>{formik.errors.view_more_url}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Max Items</Label>
                    <Input
                        type="number" onWheel={NoWheel}
                        name="max_items"
                        onChange={formik.handleChange}
                        value={formik.values.max_items || ''}
                        invalid={formik.touched.max_items && !!formik.errors.max_items}
                        disabled={loading}
                    />
                    {formik.touched.max_items && formik.errors.max_items && <FormFeedback>{formik.errors.max_items}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Items</Label>
                    {formik.values.items.map((item, index) => (
                        <Row key={index}
                            className="item-container"
                            style={{
                                ...(index >= formik.values.max_items) ? {
                                    backgroundColor: 'red'
                                } : {}
                            }}
                        >
                            <Col>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Input
                                        type="text"
                                        name={`items[${index}].title`}
                                        onChange={formik.handleChange}
                                        value={formik.values.items[index]?.title || ''}
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <Input
                                        type="text"
                                        name={`items[${index}].description`}
                                        onChange={formik.handleChange}
                                        value={formik.values.items[index]?.description || ''}
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>URL</Label>
                                    <Input
                                        type="text"
                                        name={`items[${index}].url`}
                                        onChange={formik.handleChange}
                                        value={formik.values.items[index]?.url || ''}
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Image URL</Label>
                                    <Input
                                        type="text"
                                        name={`items[${index}].image_url`}
                                        onChange={formik.handleChange}
                                        value={formik.values.items[index]?.image_url || ''}
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Label>Action</Label>
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            const newItems = [...formik.values.items];
                                            newItems.splice(index, 1);
                                            formik.setFieldValue('items', newItems);
                                        }}
                                        className="remove"
                                        disabled={loading}
                                    >
                                        <FaTimes />
                                    </Button>
                                </FormGroup>

                            </Col>
                        </Row>
                    ))}
                    <Button
                        color="primary"
                        onClick={() => {
                            formik.setFieldValue('items', [{}, ...formik.values.items]);
                        }}
                        disabled={loading}
                    >
                        Add Item
                    </Button>
                </FormGroup>
            </>
        </GenericForm >
    );
};

export default MediaForm;
