import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Row, Spinner } from 'reactstrap';

export interface IGenericFormProps {
  title?: string;
  onSubmit: () => void;
  loading: boolean;
  children?: JSX.Element;
  id?: string;
  multipleLang?: boolean;
}

const GenericForm: React.FC<IGenericFormProps> = ({ title, onSubmit, loading, children, id, multipleLang }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <Col>
          <h1>{title}</h1>
          <Card>
            <CardBody>
              <Form onSubmit={onSubmit}>
                {children}
                <FormGroup>
                  { multipleLang && id &&
                    <Row>
                      <Col style={{textAlign: "right"}}>
                        <span style={{color: "red"}}>Note: Jangan lupa save. Setiap section bahasa yang di submit tidak otomatis submit section bahasa yang lain.</span>
                      </Col>
                    </Row>
                  }

                  <Row>
                    <Col style={{textAlign: "left"}}>
                      <Button type="button" color="warning"  disabled={loading}
                        onClick={() => navigate(-1) }
                      >
                      {loading ? <Spinner size="sm" color="light" /> : 'Back'}
                      </Button>
                    </Col>
                    <Col style={{textAlign: "right"}}>
                      <Button type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : 'Submit'}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GenericForm;
