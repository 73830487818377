import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API } from '../../API';
import ShowErrorToast, { ErrorOnAction } from '../../Component/Toast/ShowErrorToast';
import { IService } from './Model';

const ServiceSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required')
});

export const useServiceUpdate = (lang?: string) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState<IService>({
    type: '',
    title: '',
    description: '',
    link_text: '',
    link_url: '',
    day: undefined,
    month: undefined,
    year: undefined,
    day_of_week: undefined,
    church_time: [],
    join_link: '',
    join_link_enable: 0,
    order: 0,
    lang: lang
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${API.SERVICE}${id}${lang ? `?lang=${lang}` : ''}`)
        .then(res => setService({
          ...res.data,
          ...(lang ? { lang: lang } : {})
        }))
        .catch(err => toast.error('Error fetching service!'))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: service,
    validationSchema: ServiceSchema,
    onSubmit: (values, { setSubmitting }) => {
      let formData = new FormData();
      if (id) {
        formData.append('_method', 'PUT');
      }
      Object.keys(values).forEach(key => {
        const d = values as any;
        if (d[key] instanceof Array) {
          d[key].forEach((item: any) => {
            formData.append(key + '[]', item);
          });
          return;
        }
        formData.append(key, d[key] ?? "");
      });

      setLoading(true);
      if (id) {
        axios
          .post(`${API.SERVICE}${id}`, formData)
          .then(res => {
            toast.success('Service updated successfully!');
          })
          .catch(err => ShowErrorToast(err, "Service", ErrorOnAction.Update))
          .finally(() => setLoading(false));
      } else {
        axios
          .post(API.SERVICE, formData)
          .then(res => {
            toast.success('Service created successfully!');
            navigate('/services/form/' + res.data.id, { replace: true });
          })
          .catch(err => ShowErrorToast(err, "Service", ErrorOnAction.Create))
          .finally(() => setLoading(false));
      }

      setSubmitting(false);
    },
  });

  const handleSubmit = formik.handleSubmit;

  return { id, service, loading, formik, handleSubmit };
};
