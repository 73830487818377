import { FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import useEmailSettingUpdate from "./useEmailSettingUpdate";
import { NoWheel } from "../../Helper/NoWheel";


export const EmailSetting = () => {
  const { loading, formik, handleSubmit } = useEmailSettingUpdate();

  return (
    <>
      <GenericForm title={'Update Email Setting'} onSubmit={handleSubmit} loading={loading}>
        <>
          <FormGroup>
            <Label htmlFor="max_email_per_send">Maximum Emails per Send</Label>
            <Input
              id="max_email_per_send"
              name="max_email_per_send"
              type="number" onWheel={NoWheel}
              onChange={formik.handleChange}
              value={formik.values.max_email_per_send}
              disabled={loading}
            />
            {formik.errors.max_email_per_send && <div className="text-danger">{formik.errors.max_email_per_send}</div>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="every_minutes">Interval in Minutes</Label>
            <Input
              id="every_minutes"
              name="every_minutes"
              type="number" onWheel={NoWheel}
              onChange={formik.handleChange}
              value={formik.values.every_minutes}
              disabled={loading}
            />
            {formik.errors.every_minutes && <div className="text-danger">{formik.errors.every_minutes}</div>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contact_us_email">Contact Us Email to</Label>
            <Input
              id="contact_us_email"
              name="contact_us_email"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.contact_us_email}
              disabled={loading}
            />
            {formik.errors.contact_us_email && <div className="text-danger">{formik.errors.contact_us_email}</div>}
          </FormGroup>
        </>
      </GenericForm>
    </>
  );
};
