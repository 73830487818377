import { FormGroup, Input, Label } from "reactstrap";
import GenericForm from "../../Component/GenericForm";
import useLinkUpdate from "./useLinkUpdate";

export const Links = () => {
  const { loading, formik, handleSubmit } = useLinkUpdate(); // Use the custom hook for Links update

  return (
    <>
      <GenericForm title={'Update Links'} onSubmit={handleSubmit} loading={loading}>
        <>
          <FormGroup>
            <Label htmlFor="instagram_url">Instagram URL</Label>
            <Input
              id="instagram_url"
              name="instagram_url"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.instagram_url}
              disabled={loading}
            />
            {formik.errors.instagram_url && <div className="text-danger">{formik.errors.instagram_url}</div>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="tiktok_url">TikTok URL</Label>
            <Input
              id="tiktok_url"
              name="tiktok_url"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.tiktok_url}
              disabled={loading}
            />
            {formik.errors.tiktok_url && <div className="text-danger">{formik.errors.tiktok_url}</div>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="facebook_url">Facebook URL</Label>
            <Input
              id="facebook_url"
              name="facebook_url"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.facebook_url}
              disabled={loading}
            />
            {formik.errors.facebook_url && <div className="text-danger">{formik.errors.facebook_url}</div>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="youtube_url">YouTube URL</Label>
            <Input
              id="youtube_url"
              name="youtube_url"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.youtube_url}
              disabled={loading}
            />
            {formik.errors.youtube_url && <div className="text-danger">{formik.errors.youtube_url}</div>}
          </FormGroup>
        </>
      </GenericForm>
    </>
  );
};
